import {
  WebpAircare_1,
  WebpAircare_2,
  WebpAircare_3,
  WebpAircare_4,
  WebpScentairbackground,
} from "../../assets";
import Footer from "../../components/Footer";

import PointsCard from "../../components/PointsCard";
import { addAnimation } from "../../utils/addAnimation";

export default function aircare() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpScentairbackground}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Air Care</div>
          <div className="about__hero__content__text">
            Transform Your Space with Our Innovative Air Care Solutions for
            <span> Clarity, Comfort, </span> and
            <span> Well-Being </span>.
          </div>
        </div>
      </section>
      <section className="scentsectionall">
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info__new">
            <div className="scent__section__heading">
              <span>Transforming Spaces with Scent:</span>
              <br />
              <div>
                {addAnimation(
                  " Crafting Air Care Fragrances for Serenity and Freshness",
                )}
              </div>
            </div>
            <div>
              {addAnimation(
                "Air care fragrances are more than just pleasant scents; they are transformative agents that shape the character of a space. The creation of a lavender-infused room spray is a prime example of this transformation, involving an intricate balance of fragrance, malodor counteraction, and space enhancement.",
              )}
            </div>
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            {addAnimation(" Case Study: Harnessing the Essence of Lavender")}
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Calming Properties"
              text="Lavender is renowned for its soothing effect. At AFF, the calming properties of lavender are captured in their purest form, setting the stage for relaxation."
            />
            <PointsCard
              heading="Harmonious Blending"
              text="Combining lavender with complementary notes creates a rich, multi-dimensional fragrance that appeals to various senses."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpAircare_3} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            Malodor Counteraction
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Odor Neutralizers"
              text="Effective malodor counteraction requires precision and understanding of chemistry. AFF's expertise in selecting neutralizers that work synergistically with lavender ensures that unpleasant odors are masked."
            />
            <PointsCard
              heading="Sensory Perception"
              text="It's not just about masking odors; it's about transforming the sensory perception of a space, turning it into a serene haven."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpAircare_4} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            Ensuring Consistent Diffusivity
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Time-Release Technology"
              text="Creating a fragrance that diffuses consistently over time requires advanced formulation. AFF's approach to time-release technology ensures that the calming scent lingers without becoming overpowering."
            />
            <PointsCard
              heading="Spray Mechanism Compatibility"
              text="The compatibility of the fragrance with different spray mechanisms is evaluated to guarantee uniform dispersion, enhancing the overall user experience."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpAircare_1} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            Crafting an Olfactory Narrative
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Storytelling Through Scent"
              text="The fragrance becomes a narrative, telling a story of tranquility and freshness, guided by the artistic and scientific insights at AFF."
            />
            <PointsCard
              heading="Space Enhancement"
              text="Beyond mere fragrance release, the room spray changes the character of a space, creating an environment that resonates with comfort and serenity.
              "
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpAircare_2} />
          </div>
        </div>
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info">
            <div>
              {addAnimation(
                "The result of this careful crafting is more than just a room spray; it's an olfactory experience that alters the ambiance, infusing it with tranquility and elegance. It's where the art of perfumery meets the science of air care, creating a seamless blend that elevates everyday living.",
              )}
            </div>
            <span>
              <div>
                {addAnimation(
                  "In the hands of the skilled professionals at AFF, air care fragrance crafting becomes an exploration of emotions, sensations, and spatial aesthetics, turning a simple scent into a transformative agent that breathes life into spaces.",
                )}
              </div>
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
