import { JpgSpotlightbackground, WebpGomez } from "../assets";

import Footer from "../components/Footer";
import React from "react";

export default function Spotlight() {
  return (
    <>
      {" "}
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={JpgSpotlightbackground}
          alt=" Mr and Mrs Gomez, Advanced Flavors & Fragrances Pte. Ltd."
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading ">
            Monthly Distributor Spotlight: <br /> Mr and Mrs Gomez
          </div>
        </div>
      </section>
      <div className="natural__flavor__container__spotlight">
        <div className="natural__flavor__container__wraper__spotlight__container">
          <div className="natural__flavor__container__wraper__contant__left">
            <div className="square">
              <div>
                <img
                  className="natural__flavor__container__wraper__contant__left__image"
                  src={WebpGomez}
                  alt="Longtail boat in Thailand"
                />
              </div>
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                At Advanced Flavors & Fragrances (AFF), we are proud to
                spotlight our esteemed distribution partners, Nerissa and
                Leonardo Gomez of Flavourium Aromatics Corporation. Their
                journey, both professionally in the flavor industry and
                personally as a couple, epitomizes the values and aspirations we
                hold dear at AFF. Nerissa had a remarkable career at VK Creative
                Inc. in Singapore, where her expertise in market trends and
                client relationships led to significant growth, especially in
                the Philippines. This professional acumen was further showcased
                when she co-founded Flavourium Aromatics together with her
                husband, Leonardo Gomez. Under her guidance, the company has
                become known for its deep market knowledge and unwavering
                commitment to customer service.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="natural__flavor__container__spotlight">
        <div className="natural__flavor__container__wraper__spotlight">
          <div className="natural__flavor__container__wraper__contant">
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              Leonardo Gomez, as the Corporate Secretary of Flavourium
              Aromatics, plays an equally crucial role. His strategic insight
              and operational acumen have been foundational in the company's
              success. More than that, Leonardo's partnership with Nerissa
              extends beyond the office, with over 40 years of marriage, a
              testament to their shared values and mutual support, both
              personally and professionally.
            </div>{" "}
            <br />
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              At{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                AFF
              </span>{" "}
              , we recognize and appreciate the unique blend of professional
              excellence and personal integrity that the Gomezes bring to their
              business. Their collaborative approach has not only advanced
              Flavourium Aromatics but has also greatly contributed to our
              successful partnership. Their combined strengths in strategic
              planning and customer focus align perfectly with{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                AFF's
              </span>{" "}
              mission to deliver quality and innovation in the flavor industry.
            </div>{" "}
            <br />
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              The story of Nerissa and Leonardo Gomez is one we admire and
              celebrate. It reflects a harmonious balance of business acumen,
              family values, and a shared vision for success. As we continue our
              collaboration with Flavourium Aromatics, we are confident that
              this partnership will bring continued growth and innovation,
              benefitting our clients and the flavor industry at large.
            </div>{" "}
            <br />
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              We at{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                AFF
              </span>{" "}
              are delighted to have the Gomez family as part of the
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {" "}
                AFF
              </span>{" "}
              family, we and look forward to many more years of fruitful
              collaboration.
            </div>{" "}
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
