import {
  WebpCosmetics_1,
  WebpCosmetics_2,
  WebpCosmetics_3,
  WebpCosmetics_4,
  WebpCosmetics_5,
  WebpScentcosmaticbackground,
} from "../../assets";
import Footer from "../../components/Footer";

import PointsCard from "../../components/PointsCard";
import { addAnimation } from "../../utils/addAnimation";

import { Helmet } from "react-helmet";
export default function Cosmetics() {
  return (
    <>
      {" "}
      <Helmet>
        <title> Cosmetics | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="Crafting a fragrance that lasts throughout the product's life cycle requires mastery over volatility rates. AFF's knowledge ensures that the aroma remains consistent from the first use to the last.
"
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="Crafting a fragrance that lasts throughout the product's life cycle requires mastery over volatility rates. AFF's knowledge ensures that the aroma remains consistent from the first use to the last.
"
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/Cosmetics"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/Cosmetics"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="Crafting a fragrance that lasts throughout the product's life cycle requires mastery over volatility rates. AFF's knowledge ensures that the aroma remains consistent from the first use to the last.
"
        />
        <link rel="canonical" href="https://www.advancedff.com/Cosmetics" />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Cosmetics"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Cosmetics"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Cosmetics"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Cosmetics"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpScentcosmaticbackground}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Cosmetics</div>
          <div className="about__hero__content__text">
            Elevate Your Beauty Products with
            <span> Our Exquisite Fragrance </span> Infusions in Cosmetics, Where
            Innovation Meets <span> Allure </span>.
          </div>
        </div>
      </section>
      <section className="scentsectionall">
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info__new">
            <div className="scent__section__heading">
              <div>
                <span>{addAnimation("Indulgence in a Jar:")}</span> <br />
              </div>

              {addAnimation(
                " The Art and Science of Fragrancing Luxury cosmetics"
              )}
            </div>
            <div>
              {addAnimation(
                "Fragrances in cosmetics do more than simply scent the product; they amplify its appeal and elevate the user's experience. Take for instance the creation of a fragrance for a luxury face cream is a journey that combines artistry, technical precision, and consumer insight."
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          Factoring in Volatility Rates
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Scent Longevity"
            text="Crafting a fragrance that lasts throughout the product's life cycle requires mastery over volatility rates. AFF's knowledge ensures that the aroma remains consistent from the first use to the last."
          />
          <PointsCard
            heading="Layering Complexity"
            text="Designing an evolving scent profile that unfolds over time adds depth and sophistication to the experience."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpCosmetics_2} />
        </div>
      </div>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          Considering Packaging Interaction
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Material Compatibility"
            text="The interaction between the fragrance and packaging materials must be carefully assessed. AFF's rigorous testing guarantees that the aroma stays true within the chosen packaging."
          />
          <PointsCard
            heading="Aesthetic Harmony"
            text="The fragrance's character should align with the packaging design, creating a cohesive visual and olfactory experience."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpCosmetics_3} />
        </div>
      </div>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          Understanding Market Appeal
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Target Audience Insights"
            text="Knowing the preferences and desires of the target market is essential. AFF's insights into luxury cosmetic consumers guide the fragrance's direction."
          />
          <PointsCard
            heading="Trend Awareness"
            text="Staying abreast of emerging fragrance trends ensures that the scent reflects contemporary tastes while maintaining a timeless elegance."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpCosmetics_4} />
        </div>
      </div>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          Creating an Immersive Experience
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Sensory Journey"
            text="The fragrance doesn’t merely exist; it immerses the user in an indulgent experience, beginning the moment they uncap the jar."
          />
          <PointsCard
            heading="Emotional Connection"
            text="Crafting an aroma that resonates emotionally creates a deeper connection with the user, turning a daily skincare routine into a luxurious ritual."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpCosmetics_1} />
        </div>
      </div>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          Compliance and Sustainability
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Regulatory Adherence"
            text="Meeting industry regulations and safety standards is a fundamental aspect of the development process, reflecting AFF's commitment to quality."
          />
          <PointsCard
            heading="Ethical Sourcing"
            text="Embracing ethical sourcing practices aligns with modern values, adding another layer of appeal to the luxury product."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpCosmetics_5} />
        </div>
      </div>
      <section className="scentsectionall">
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info">
            <div>
              {addAnimation(
                "The result of this intricate process is more than just a scented face cream; it's an indulgence, a sensory delight that transcends mere functionality. It's where the finesse of fine perfumery meets the practicality of skincare, creating a harmonious blend that delights and nourishes."
              )}
            </div>
            <br />
            <br />
            <div>
              {addAnimation(
                "In the hands of AFF, the craft of fragrance cosmetics and skincare becomes a symphony of senses, where technical precision meets creative expression, turning a simple cosmetic into an unforgettable experience."
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
