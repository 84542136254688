import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { WebpPrivacyBg } from "../assets";
import { addAnimation } from "../utils/addAnimation";

export default function Privacy() {
  return (
    <>
      <Helmet>
        <title> Privacy | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="We’re not just about aromas and tastes; we’re about making sure you have the best experience with us. When you interact with our websites or services, we might collect some info about you. Do not worry; it's all to make our offerings better suited to your needs"
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="We’re not just about aromas and tastes; we’re about making sure you have the best experience with us. When you interact with our websites or services, we might collect some info about you. Do not worry; it's all to make our offerings better suited to your needs"
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta name="twitter:url" content="https://www.advancedff.com/Privacy" />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:url" content="https://www.advancedff.com/Privacy" />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="We’re not just about aromas and tastes; we’re about making sure you have the best experience with us. When you interact with our websites or services, we might collect some info about you. Do not worry; it's all to make our offerings better suited to your needs"
        />
        <link rel="canonical" href="https://www.advancedff.com/Privacy" />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Privacy"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Privacy"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Privacy"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/Privacy"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpPrivacyBg}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Privacy Statement</div>
        </div>
      </section>
      <section className="natural__flavor__container">
        <div className="natural__flavor__container__wraper">
          <div className="natural__flavor__container__wraper__contant">
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "Hey there! Welcome to the family at Advanced Flavors & Fragrances (AFF), also known as your go-to place for all things flavors and fragrances. Before we get started, we know 'Privacy Policy' might sound super formal, but we take your privacy seriously. We also believe in keeping things clear and simple. So here's what you need to know:"
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation(" Your Data and Why We Collect It")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "We’re not just about aromas and tastes; we’re about making sure you have the best experience with us. When you interact with our websites or services, we might collect some info about you. Do not worry; it's all to make our offerings better suited to your needs."
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation("Types of Data We Collect")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              <span className="natural__flavor__container__wraper__contant__heading__sub__points">
                Your name
              </span>
              , so we know how to greet you.
              <br />
              <span className="natural__flavor__container__wraper__contant__heading__sub__points">
                Email
              </span>
              , to keep you updated on the exciting stuff.
              <br />
              <span className="natural__flavor__container__wraper__contant__heading__sub__points">
                Contact details
              </span>
              , so we can reach you for important matters.
              <br />
              <span className="natural__flavor__container__wraper__contant__heading__sub__points">
                Browsing habits on our site
              </span>
              , to make our pages more interesting for you.
              <br />
              <span className="natural__flavor__container__wraper__contant__heading__sub__points">
                Other job-related info
              </span>
              , if you are looking for business opportunities.
              <br />
              <span className="natural__flavor__container__wraper__contant__heading__sub__points">
                {" "}
                Your Safety is Our Priority.
              </span>
              <br />
              <br />
              {addAnimation(
                `We are committed to keeping your data safe and sound. We’ve put in place some cool (and very technical) safety measures to make sure your data stays private.`
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation("Sharing is Caring, But There Are Limits")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                `Sometimes we may share your data with trusted partners or legal bodies, but only when we absolutely have to, and always in compliance with privacy laws.`
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation("Cookies & You")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                `We use cookies to make your experience smoother and more personalized. Feel free to adjust your settings anytime.`
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation("Keeping You in the Loop")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                `We might update this policy from time to time to stay compliant and transparent. So do check in occasionally to stay updated.`
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation("Talk to Us!")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              If you have any questions or need more details, you can reach out
              to our Data Protection Officer at{" "}
              <a href="mailto:dpo@advancedff.com">dpo@advancedff.com</a>. We are
              always here to help!
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
