import { useEffect, useState } from "react";

import ClickAwayListener from "react-click-away-listener";
import { Link } from "react-router-dom";
import MegaMenuContent from "./MegaMenuContent";
import MegaMenuList from "./MegaMenuList";

export default function MegaMenu({ onClickAway, data }) {
  const [show, setShow] = useState(data[0]?.title);

  useEffect(() => {
    setShow(data[0]?.title);
  }, [data]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className="megamenu">
        <div className="megamenu__content">
          <div className="megamenu__content__column">
            <ul className="megamenu__content__column__list">
              {data?.map((item) => (
                <li
                  key={item?.title}
                  className={`megamenu__content__column__list__item ${
                    item?.title === show &&
                    "megamenu__content__column__list__item__active"
                  }`}
                  onMouseOver={() => setShow(item?.title)}
                >
                  <Link
                    to={item?.titleLink}
                    className="megamenu__content__column__list__item__link"
                  >
                    {item?.title}
                  </Link>
                  {item?.list && (
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="3"
                        viewBox="0 0 20 20"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {data.find((item) => item?.title === show)?.list ? (
            <MegaMenuList
              data={data.find((item) => item?.title === show)?.list}
              onClickAway={onClickAway}
            />
          ) : (
            <MegaMenuContent
              data={data.find((item) => item?.title === show)}
              onClickAway={onClickAway}
            />
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
}
