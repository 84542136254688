import {
  WebpPersonalcare_1,
  WebpPersonalcare_2,
  WebpPersonalcare_3,
  WebpPersonalcare_4,
  WebpScentpersonalbackground,
} from "../../assets";

import Footer from "../../components/Footer";
import PointsCard from "../../components/PointsCard";
import { addAnimation } from "../../utils/addAnimation";

export default function Scentpersonalcare() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpScentpersonalbackground}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Personal Care</div>
          <div className="about__hero__content__text">
            Unlock Beauty and Wellness with
            <span> Our Research-Driven,</span>
            <span>Tailor-Made </span>Solutions.
          </div>
        </div>
      </section>
      <section className="scentsectionall">
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info__new">
            <div className="scent__section__heading">
              <span> Soothing Science: </span>
              <br />
              Crafting Personal Care Fragrances for Restful Nights
            </div>
            <div>
              {addAnimation(
                "Creating a fragrance for a personal care product is a thoughtful process that intertwines science and art. Consider a body lotion designed for nighttime use. We delve deep into the science of calming olfactory molecules and skin-compatible fixatives, and align it with consumer insights for night-time skincare routines. The result? An aroma that whispers relaxation and complements the soothing texture of the lotion."
              )}
              <div>
                {addAnimation(
                  "Creating a fragrance for a personal care product, such as a body lotion designed for nighttime use, is a thoughtful and complex process. At AFF, this is where the intricacies of science and art are meticulously woven together."
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            Understanding the Science
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Olfactory Molecules"
              text="Selecting calming olfactory molecules requires deep expertise. Ingredients like lavender and chamomile are chosen for their calming effects, guided by AFF's research."
            />
            <PointsCard
              heading="Skin-Compatible Fixatives"
              text="Ensuring the fragrance adheres to the skin without irritation is paramount. AFF's approach includes using skin-compatible fixatives to prolong the scent."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpPersonalcare_1} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            Incorporating Consumer Insights
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Night-time Skincare Routines"
              text="Recognizing consumer behaviour for night time skincare is vital. AFF's alignment with specific needs and rituals enhances the product's appeal."
            />
            <PointsCard
              heading="Emotional Resonance"
              text="Creating fragrances that resonate emotionally is essential, focusing on relaxation and tranquillity."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpPersonalcare_2} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            Technical Challenges and Solutions
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Balancing Act"
              text="Achieving the right balance between intensity, longevity, and stability requires meticulous formulation and testing, a specialty at AFF."
            />
            <PointsCard
              heading="Compliance and Safety"
              text="Meeting regulatory standards and ensuring hypoallergenic properties is a core consideration, reflecting AFF's commitment to safety."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpPersonalcare_3} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            Synergy with Other Product Attributes
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Texture Complementing"
              text="The fragrance must complement the lotion's texture, an aspect carefully considered at AFF."
            />
            <PointsCard
              heading="Brand Alignment"
              text="Crafting a fragrance that reflects brand values is key, making the fragrance an integral part of the brand's story."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpPersonalcare_4} />
          </div>
        </div>
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info__new">
            <div>
              {addAnimation(
                "The result of this thoughtful process is more than just a scented lotion; it's an invitation to unwind, relax, and prepare for restful sleep. It's a blend of innovative science, consumer psychology, and artistic expression."
              )}
            </div>
            <div>
              {addAnimation(
                "In the hands of AFF, fragrance crafting becomes a journey of discovery and innovation. The understanding of chemistry, consumer behavior, and aesthetics leads to creations that delight the senses and nourish the soul, turning a mundane task into a pleasurable experience."
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
