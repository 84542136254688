import React from "react";
import {
  JpgTimelinesectionimg,
  JpgTimelinesectionimg1,
  JpgTimelinesectionimg2,
} from "../assets";
import { Fade } from "react-reveal";
import { addAnimation } from "../utils/addAnimation";

export default function Historymobile() {
  const Data = [
    {
      year: 1970,
      heading: "The Dawn of a Flavor Pioneer",
      subheading: "Flavor Pioneer",
      info: "Mr Victor Koh exceptional sensory talent was recognised by Bush Boake Allen (BBA), and he became the first Flavorist in Singapore and South East Asia. His background and capabilities led to the first creation of the world’s Pandan, Durian and various other flavors. He was tapped to be the Principal Flavorist of BBA.",
      img: JpgTimelinesectionimg,
      id: 1,
    },
    {
      year: 1980,
      heading: " Ascending to New Heights",
      subheading: "New Heights",
      info: "His tenure at BBA (later integrated into IFF) is marked by legendary status, lauded by the CEO of BBA to be the best creative Flavorist of his time. This culminated in his role to lead the Flavor Division at Haarmann & Reimer where he introduced iconic tropical flavors like Guava and Gula Melaka. These flavors forge a bridge between East and West, establishing new culinary standards. Haarmann & Reimer eventually merges with Dragoco and is today known as Symrise AG.",
      img: JpgTimelinesectionimg1,
      id: 2,
    },
    {
      year: 1989,
      heading: "Founding VK with Vision",
      subheading: "Vision",
      info: "Motivated by a vision to revolutionize flavor choices, Victor Koh establishes VK Aromatics International and Creative Flavours Fragrances. Rooted in the principles of authenticity (REAL TASTE) and value (V.F.M Flavors), VK challenges the status quo, introducing a new era of flavor diversity and depth that resonates across culinary landscapes.",
      img: JpgTimelinesectionimg2,
      id: 3,
    },
    {
      year: 1990,
      heading: "Seizing the Southeast Asian Palate",
      subheading: "Asian Palate",
      info: "VK becomes synonymous with innovation, igniting the Wintermelon Flavor phenomenon in Taiwan in 1993. This success underpins expansion across Southeast Asia, with strategic moves into the Philippines and Malaysia to meet the growing demand for VK's groundbreaking Vanilla Flavors.",
      img: JpgTimelinesectionimg,
      id: 4,
    },
    {
      year: 1996,
      heading: "VK Sets Foot in the Philippines",
      subheading: "the Philippines",
      info: "VK marked a milestone by establishing its presence in the Philippines, reinforcing its dedication to exploring and integrating the rich flavors of Southeast Asia. This strategic move enabled VK to work closely with local markets, tailoring flavors that resonate with the Filipino palate and cultural culinary heritage.",
      img: JpgTimelinesectionimg1,
      id: 5,
    },
    {
      year: 1998,
      heading: "VK's Venture into Malaysia",
      subheading: "into Malaysia",
      info: "Expanding further, VK opened manufacturing units in Johor Bahru, Malaysia, focusing on the innovative production of Vanilla Flavors. This strategic location not only facilitated access to prime resources but also underscored VK's commitment to excellence and innovation in the flavor industry within the Southeast Asian region.",
      img: JpgTimelinesectionimg2,
      id: 6,
    },
    {
      year: 1998,
      heading: "VK Australia",
      subheading: "Australia",

      info: "VK's foray into Australia marks a significant milestone, demonstrating the universal appeal of VK Flavors. This period is also defined by a commitment to natural flavor research, meeting the consumer's growing preference for authenticity.",
      img: JpgTimelinesectionimg,
      id: 7,
    },
    {
      year: 2000,
      heading: "The Vanilla Revolution",
      subheading: "Revolution",

      info: "A decade of research culminates in the launch of a revolutionary Vanilla Flavor range. Vanilla Flavors predominantly relied on Vanillin as a raw material. Mr Victor Koh created a new range of Vanilla Flavors created from a different structure. These flavors, heralded for their innovation, quickly rise to prominence, reinforcing VK's reputation as a flavor innovator.",
      img: JpgTimelinesectionimg1,
      id: 8,
    },
    {
      year: 2010,
      heading: " Embracing the Pacific ",
      subheading: "Pacific",

      info: "The demand for VK flavors extended to the remote Pacific Islands, including Mauritius, illustrating the global demand for unique and quality-driven flavors.",
      img: JpgTimelinesectionimg2,
      id: 9,
    },
    {
      year: 2021,
      heading: "A New Era of Leadership",
      subheading: "Leadership",

      info: "Augustine Koh, embracing the legacy of innovation and excellence creates VKA Creative International to continue this legacy. His leadership marks the beginning of a new chapter, with a focus on sustaining the pioneering spirit and expanding VK's horizons.",
      img: JpgTimelinesectionimg1,
      id: 10,
    },
    {
      year: 2022,
      heading: " The Birth of VKA-AFF",
      subheading: "VKA-AFF",

      info: "Augustine Koh saw the need to marry cutting-edge technology with five decades of artisanal flavor crafting. A strategic merger with Advanced Flavors & Fragrances ushers in the VKA-AFF era. This collaboration not only diversifies the product range but also inaugurates an R&D Centre with cutting-edge tools and an Automated Flavor Production facility at JTC Food Hub @ Senoko, showcasing a commitment to innovation and sustainability.",
      img: JpgTimelinesectionimg,
      id: 11,
    },
    {
      year: 2023,
      heading: "Technological Milestones",
      subheading: "Milestones",

      info: "With investments  in Spray Drying Technology, VKA-AFF introduces spray-dried VK Flavors, a testament to the brand's innovative edge. This advancement offers new applications and stability for flavors, cementing VKA-AFF's status as an industry pioneer.",
      img: JpgTimelinesectionimg1,
      id: 12,
    },
  ];
  return (
    <>
      <div className="Historymobile">
        {/* Item 1 */}
        <div className="history">
          <div className="history__flag-wrapper">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time"> {addAnimation("1970")}</span>
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation(" The Dawn of a Flavor Pioneer")}
            </span>
          </div>{" "}
          <Fade delay={200}>
            <div className="history__desc">
              <span>Victor Koh’s </span>
              journey as a <span>Flavorist </span> begins at the age of 17,
              where he is mentored by the legendary{" "}
              <span> Dr. Jules Borel. </span>This marks the start of a lifelong
              passion for flavors, where he pioneers the development of iconic
              flavors like Durian and Pandan. His work at the Singapore Food
              Industries (SFI) is instrumental in shaping the local culinary
              landscape, introducing new flavors{" "}
              <span> that resonate with the local palate. </span>
            </div>
          </Fade>
          <Fade delay={200}>
            {" "}
            <img
              src={JpgTimelinesectionimg}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />{" "}
          </Fade>
        </div>
        {/* Item 2 */}
        <div className="history">
          <div className="history__flag-wrapper_2">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {" "}
                  {addAnimation("1980")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("Ascending to New Heights ")}
            </span>
          </div>
          <Fade delay={500}>
            <div className="history__desc">
              His tenure at <span> BBA (later integrated into IFF) </span> is
              marked by <span> legendary </span> status, lauded by the CEO of
              BBA to be the <span> best creative </span>Flavorist of his time.
              This culminated in his role to lead the Flavor Division at
              <span> Haarmann & Reimer </span> where he introduced iconic
              tropical flavors like Guava and Gula Melaka. These flavors forge a
              bridge between East and West, establishing new culinary standards.
              Haarmann & Reimer{" "}
              <span>
                {" "}
                eventually merges with Dragoco and is today known as Symrise AG.{" "}
              </span>
            </div>
          </Fade>
          <Fade delay={600}>
            {" "}
            <img
              src={JpgTimelinesectionimg1}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />{" "}
          </Fade>
        </div>
        {/* Item 3 */}
        <div className="history">
          <div className="history__flag-wrapper">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {addAnimation("1989")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("Founding VK with Vision")}
            </span>
          </div>{" "}
          <Fade delay={600}>
            {" "}
            <div className="history__desc">
              <span> Motivated by a vision </span> to revolutionize flavor
              choices, Victor Koh establishes{" "}
              <span>
                {" "}
                VK Aromatics International and Creative Flavours Fragrances.{" "}
              </span>{" "}
              Rooted in the principles of authenticity (REAL TASTE) and value
              (V.F.M Flavors), <span> VK challenges </span> the status quo,
              introducing a new era of flavor diversity and depth that resonates
              across <span> culinary landscapes.</span>
            </div>{" "}
          </Fade>
          <Fade delay={900}>
            <img
              src={JpgTimelinesectionimg2}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />
          </Fade>
        </div>
        <div className="history">
          <div className="history__flag-wrapper_2">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {" "}
                  {addAnimation("1990")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("Seizing the Southeast Asian Palate  ")}
            </span>
          </div>
          <Fade delay={500}>
            <div className="history__desc">
              <span>VK </span> becomes synonymous with innovation, igniting the
              Wintermelon Flavor <span> phenomenon in Taiwan in 1993</span>.
              This success underpins expansion across Southeast Asia, with
              strategic moves into the <span> Philippines and Malaysia </span>{" "}
              to meet the growing demand for VK's groundbreaking Vanilla
              Flavors.
            </div>
          </Fade>
          <Fade delay={600}>
            {" "}
            <img
              src={JpgTimelinesectionimg1}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />{" "}
          </Fade>
        </div>
        <div className="history">
          <div className="history__flag-wrapper">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {addAnimation("1996")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("VK Sets Foot in the Philippines")}
            </span>
          </div>{" "}
          <Fade delay={600}>
            {" "}
            <div className="history__desc">
              <span>VK </span> marked a milestone by establishing its presence
              in the Philippines, reinforcing its dedication to exploring and
              integrating the rich flavors of <span> Southeast Asia. </span>{" "}
              This strategic move enabled VK to work closely with local markets,
              tailoring flavors that resonate with the Filipino palate and{" "}
              <span> cultural culinary heritage. </span>
            </div>{" "}
          </Fade>
          <Fade delay={900}>
            <img
              src={JpgTimelinesectionimg2}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />
          </Fade>
        </div>
        <div className="history">
          <div className="history__flag-wrapper_2">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {" "}
                  {addAnimation("1998")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("VK's Venture into Malaysia  ")}
            </span>
          </div>
          <Fade delay={500}>
            <div className="history__desc">
              <span> Expanding further, VK opened manufacturing </span>units in{" "}
              <span>Johor Bahru, Malaysia, </span> focusing on the innovative
              production of Vanilla Flavors. This strategic location not only{" "}
              <span>facilitated</span> access to prime resources but also
              underscored VK's commitment to{" "}
              <span> excellence and innovation </span> in the flavor industry
              within <span>the Southeast Asian region. </span>
            </div>
          </Fade>
          <Fade delay={600}>
            {" "}
            <img
              src={JpgTimelinesectionimg1}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />{" "}
          </Fade>
        </div>
        {/* //// */}
        <div className="history">
          <div className="history__flag-wrapper">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {addAnimation("1998")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("VK Australia")}
            </span>
          </div>{" "}
          <Fade delay={600}>
            {" "}
            <div className="history__desc">
              <span> VK's foray </span> into Australia marks a significant
              milestone, demonstrating the universal <span>appeal of VK </span>{" "}
              Flavors. This period is also defined by a commitment to natural
              flavor research, meeting the consumer's growing{" "}
              <span> preference for authenticity. </span>
            </div>{" "}
          </Fade>
          <Fade delay={900}>
            <img
              src={JpgTimelinesectionimg2}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />
          </Fade>
        </div>
        <div className="history">
          <div className="history__flag-wrapper_2">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {" "}
                  {addAnimation("2000")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("The Vanilla Revolution ")}
            </span>
          </div>
          <Fade delay={500}>
            <div className="history__desc">
              A decade of research culminates in the launch of a Vanilla{" "}
              <span>revolutionary </span>
              Flavor range. Vanilla Flavors predominantly relied on Vanillin as
              a raw material. <span> Mr Victor Koh </span> created a new range
              of Vanilla Flavors created from a different structure. These
              flavors, heralded for their{" "}
              <span> innovation, quickly rise to prominence,</span>
              reinforcing <span>VK's reputation as a flavor innovator.</span>
            </div>
          </Fade>
          <Fade delay={600}>
            {" "}
            <img
              src={JpgTimelinesectionimg1}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />{" "}
          </Fade>
        </div>
        {/* <  */}{" "}
        <div className="history">
          <div className="history__flag-wrapper">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {addAnimation("2010")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("Embracing the Pacific ")}
            </span>
          </div>{" "}
          <Fade delay={600}>
            {" "}
            <div className="history__desc">
              <span> The demand for VK flavors</span> extended to the remote
              Pacific Islands, including Mauritius,{" "}
              <span>illustrating the global demand</span> for unique and
              quality-driven flavors.{" "}
            </div>{" "}
          </Fade>
          <Fade delay={900}>
            <img
              src={JpgTimelinesectionimg2}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />
          </Fade>
        </div>
        <div className="history">
          <div className="history__flag-wrapper_2">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {" "}
                  {addAnimation("2021")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("A New Era of Leadership ")}
            </span>
          </div>
          <Fade delay={500}>
            <div className="history__desc">
              <span> Augustine Koh, </span> embracing the legacy of innovation
              and excellence creates <span>VKA Creative</span> International to
              continue this legacy <span> His leadership</span> marks the
              beginning of a new chapter, with a focus on sustaining the
              pioneering spirit and
              <span>expanding VK's horizons.</span>{" "}
            </div>
          </Fade>
          <Fade delay={600}>
            {" "}
            <img
              src={JpgTimelinesectionimg1}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />{" "}
          </Fade>
        </div>
        {/* ,.. */}
        <div className="history">
          <div className="history__flag-wrapper">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {addAnimation("2022")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("The Birth of VKA-AFF  ")}
            </span>
          </div>{" "}
          <Fade delay={600}>
            {" "}
            <div className="history__desc">
              <span>Augustine Koh</span> saw the need to marry cutting-edge
              technology with five decades{" "}
              <span>of artisanal flavor crafting</span>. A strategic merger with
              Advanced Flavors & Fragrances ushers in the VKA-AFF era. This{" "}
              <span>collaboration</span> not only diversifies the product range
              but also inaugurates an R&D Centre with{" "}
              <span> cutting-edge </span>
              tools and an Automated Flavor Production facility at{" "}
              <span>JTC Food Hub @ Senoko,</span> showcasing a commitment to{" "}
              <span>innovation and sustainability.</span>
            </div>{" "}
          </Fade>
          <Fade delay={900}>
            <img
              src={JpgTimelinesectionimg2}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />
          </Fade>
        </div>
        <div className="history">
          <div className="history__flag-wrapper_2">
            <span className="history__time-wrapper">
              <Fade up delay={100}>
                {" "}
                <span className="history__time">
                  {" "}
                  {addAnimation("2023")}
                </span>{" "}
              </Fade>
            </span>{" "}
            <span className="history__flag">
              {addAnimation("Technological Milestones ")}
            </span>
          </div>
          <Fade delay={500}>
            <div className="history__desc">
              With investments in Spray Drying Technology, <span>VKA-AFF</span>{" "}
              introduces spray-dried VK Flavors, a testament to the{" "}
              <span>brand's innovative edge</span>. This advancement offers new
              applications and stability for flavors, cementing{" "}
              <span>VKA-AFF's status as an industry pioneer</span>.
            </div>
          </Fade>
          <Fade delay={600}>
            {" "}
            <img
              src={JpgTimelinesectionimg1}
              alt="Timelinesectionimg"
              className="Timeline__Section__content__right__content__img"
            />{" "}
          </Fade>
        </div>
      </div>
    </>
  );
}
