import { WebpHome_1, WebpHome_2, WebpHomesecimg } from "../../assets";
import Footer from "../../components/Footer";

import PointsCard from "../../components/PointsCard";
import { addAnimation } from "../../utils/addAnimation";

export default function personalcare() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpHomesecimg}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Home Care</div>
          <div className="about__hero__content__text">
            Innovative Home Care Solutions for
            <span> Comfort, Cleanliness,</span> and
            <span> Conscious Living </span>.
          </div>
        </div>
      </section>
      <section className="scentsectionall">
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info">
            <div>
              {addAnimation(
                "Home care fragrances need to satisfy two key elements: functional performance and emotional satisfaction. With a lemon-scented floor cleaner, for instance, we ensure the fragrance stands up against alkaline environments and simultaneously enhances user experience. The aroma does not merely mask the product's chemical profile; it delivers an uplifting sensory message that enhances the perception of cleanliness.",
              )}
            </div>
            <br />
            <div>
              {addAnimation(
                "Crafting fragrances for home care products is an intricate blend of science and art, driven by both functional performance and emotional satisfaction.",
              )}
            </div>
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            {addAnimation(" Functional Performance")}
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Chemical Stability"
              text="Ensuring that a lemon-scented floor cleaner maintains its fresh aroma in an alkaline environment requires careful selection of components. These must resist degradation, offering a consistent and appealing fragrance over time."
            />
            <PointsCard
              heading="Masking Unpleasant Odors"
              text="It's not simply about hiding the chemical profile. The balance between different scent notes is essential, creating a complementary fragrance that does not overpower."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpHome_1} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            {addAnimation(" Emotional Satisfaction")}
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Enhancing User Experience"
              text="The fragrance does more than mask odors; it sends a sensory message. The fresh lemon scent in a cleaner, for example, conveys cleanliness and invigoration, elevating the cleaning experience."
            />
            <PointsCard
              heading="Aligning with Consumer Trends"
              text="Understanding and aligning with current consumer preferences, such as eco-friendly products, is crucial. It involves adjusting formulations to meet emerging demands."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpHome_1} />
          </div>
        </div>
        <div className="scentsectionall__content__points__section">
          <div className="scentsectionall__content__main__heading">
            {addAnimation("  Challenges and Solutions")}
          </div>
          <div className="scentsectionall__content__points">
            <PointsCard
              heading="Compatibility with Other Ingredients"
              text="This is where our professional expertise shines. Meticulous testing and adjustments ensure that the fragrance components work in harmony with other ingredients, such as surfactants and solvents."
            />
            <PointsCard
              heading="Regulatory Compliance"
              text="Adhering to regulations is a daily practice, ensuring that the creation of appealing fragrances also aligns with safety and ethical guidelines."
            />
            <PointsCard
              heading="Cost Efficiency"
              text="Striking the balance between quality and cost involves strategic sourcing and efficient formulation techniques. It's a challenge, but one that AFF navigates with skill."
            />
          </div>
          <div className="scentsectionall__content__main__img">
            <img loading="lazy" src={WebpHome_2} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
