import {
  WebpFrangrance_1,
  WebpFrangrance_2,
  WebpFrangrance_3,
  WebpFrangrance_4,
  WebpScentfinecarebackground,
} from "../../assets";
import Footer from "../../components/Footer";

import PointsCard from "../../components/PointsCard";
import { addAnimation } from "../../utils/addAnimation";

export default function finecare() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpScentfinecarebackground}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Fine Fragrance</div>
          <div className="about__hero__content__text">
            Experience Fine Fragrance Crafted Through
            <span> Artistic Innovation </span> and
            <span> Timeless Elegance </span>.
          </div>
        </div>
      </section>
      <section className="scentsectionall">
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info__new">
            <div className="scent__section__heading">
              <span>{addAnimation(" A Mediterranean Symphony:")}</span> <br />
              {addAnimation(
                "Crafting Fine Fragrances Through Artistry and Innovation",
              )}
            </div>
            <div>
              {addAnimation(
                "The fine fragrance sector is a realm of continuous evolution, creativity, and precision. When creating a luxury perfume inspired by a Mediterranean summer, the process becomes a delicate dance of artistry, science, and market insights.",
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          {addAnimation("Monitoring Olfactory Trends")}
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Trend Analysis"
            text="Keeping abreast of global fragrance trends is essential. AFF's expertise in identifying emerging olfactory directions ensures that the perfume resonates with contemporary tastes."
          />
          <PointsCard
            heading="Regional Preferences"
            text="Understanding the nuances of different markets allows for a fragrance that appeals across cultures, reflecting the universal allure of a Mediterranean summer."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpFrangrance_1} />
        </div>
      </div>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          {addAnimation("   Factoring in Evaporation Kinetics")}
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Top, Heart, and Base Notes"
            text="Crafting the olfactory pyramid is a precise science. From the fresh top notes to the enduring base, each layer is thoughtfully curated to unfold in harmony."
          />
          <PointsCard
            heading="Longevity and Sillage"
            text="At AFF, the evaporation kinetics are meticulously studied to ensure that the fragrance lingers, creating a lasting sensory picture without overwhelming the senses."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpFrangrance_2} />
        </div>
      </div>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          {addAnimation(" Understanding Consumer Preferences")}
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Demographic Insights"
            text="Tailoring the fragrance to specific consumer segments requires insights into preferences, lifestyles, and desires, a focus area at AFF."
          />
          <PointsCard
            heading="Sustainability and Ethical Considerations"
            text="Aligning with modern values, such as sustainability and ethical sourcing, adds another layer of appeal to the fragrance."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpFrangrance_3} />
        </div>
      </div>
      <div className="scentsectionall__content__points__section">
        <div className="scentsectionall__content__main__heading">
          {addAnimation("  Balancing Artistry with Market Reception")}
        </div>
        <div className="scentsectionall__content__points">
          <PointsCard
            heading="Creative Expression"
            text="The perfume becomes a canvas, where scents paint a vivid picture of sun-kissed shores and gentle breezes, guided by the artistic vision of AFF's perfumers."
          />
          <PointsCard
            heading="Brand Alignment"
            text="Ensuring that the fragrance aligns with the brand's identity and story creates a cohesive and compelling narrative that resonates with consumers."
          />
        </div>
        <div className="scentsectionall__content__main__img">
          <img loading="lazy" src={WebpFrangrance_4} />
        </div>
      </div>
      <section className="scentsectionall">
        <div className="scentsectionall__content">
          <div className="scentsectionall__content__info">
            <div>
              {addAnimation(
                "The result of this multifaceted process is more than just a fragrance; it's a sensory journey to the Mediterranean, a luxurious escape captured in a bottle. It's where the allure of a summer's day meets the sophistication of fine perfumery.",
              )}
            </div>
            <br />
            <br />
            <div>
              {addAnimation(
                "In the hands of skilled professionals at AFF, fragrance crafting transcends mere scent-making. It becomes an alchemy of emotions, memories, and aspirations, turning an inspired idea into a tangible, delightful reality.",
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
