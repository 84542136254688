import { WebpScentIngredientscarebackground } from "../../assets";
import Footer from "../../components/Footer";

export default function Specialty() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpScentIngredientscarebackground}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">
            Specialty Aroma Chemicals
          </div>
          <div className="about__hero__content__text">
            In the realm of aroma innovation at AFF, we are reshaping the
            ordinary to offer you an exceptional collection of Specialty Aroma
            Chemicals. Designed with the specific needs of Flavor and Fragrance
            composition in mind, these are not just substitutes - they are
            superior enhancements.
          </div>
        </div>
      </section>
      <section className="scentsectionall">
        <div className="scentsectionall__contentsss">
          <div className="scentsectionall__content__info__heading">
            Consider our VNL500P: a Vanilla substitute that packs five times the
            potency of its conventional counterpart, offering a rich fusion of
            sweet, creamy, and spicy notes. Or take the EM300l is our unique
            take on Ethyl Maltol is in a user-friendly liquid form that fits
            seamlessly into your formulation. And for that smoky, lingering
            heat, there's CAP300P: our Capsicum substitute in an easy-to-handle
            powder form.
          </div>
          <div className="scentsectionall__content__info__heading">
            These are just a few highlights from our wide range of Specialty
            Aroma Chemicals – many of which are popular choices among flavor
            houses and food manufacturers with in-house R&D teams. We
            continuously work on developing more innovative substitutes tailored
            to fit the complex requirements of today's flavor and fragrance
            market.
          </div>
          <div className="scentsectionall__content__info__heading">
            Our specialized chemicals come in various forms – liquid, powder,
            oil-based, and water-based – for easy integration into your
            processes. And if you have a particular need, we are here to listen
            and create a tailor-made aroma substitute suitable for your
            application. At AFF, we are not just suppliers; we are your partners
            in creation, providing you with the tools to elevate your flavor and
            fragrance compositions. With our Specialty Aroma Chemicals,
            redefining the standards of taste and smell is within your reach.
            Let's create the extraordinary together.
          </div>

          <div className="scentsectionall__content__info__all__contentttt">
            <div className="scentsectionall__content__info__all">
              <span className="scentsectionall__content__info__all__heading">
                CAP300P
              </span>
              <br />
              <br />
              Our unique substitute for Capsicum is enhanced with 3 times the
              potency and a lingering smoky note.
              <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Powder. <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.05 - 0.2% in the final product. <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Ideal for spice blends, snack seasonings, and savory bakery
              products. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Hot and spicy aroma with a smoky undertone. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Its powder form allows for seamless integration into dry mix
              applications, offering an even distribution of the spicy-smoky
              flavor. Furthermore, for heat-sensitive manufacturing processes,
              it can provide the desired capsaicin punch without the need for
              added liquids, maintaining the integrity of the final product
              texture.
            </div>
            <div className="scentsectionall__content__info__all__withoutbackground">
              <span className="scentsectionall__content__info__all__heading">
                BENZ350OL
              </span>
              <br />
              <br />
              Our proprietary substitute for Benzaldehyde is enhanced with 3.5x
              more potency and an exotic tropical note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Oil-based Liquid. <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.05 - 0.2% in the final product. <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Suitable for beverage, dessert, and confectionery applications.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Almond-like aroma with a tropical edge. <br />
              <span className="scentsectionall__content__info__all__from">
                Advantages :{" "}
              </span>
              Its oil-soluble formulation is ideal for fat-based systems,
              ensuring a consistent tropical-almond flavor experience.
            </div>
            <div className="scentsectionall__content__info__all">
              <span className="scentsectionall__content__info__all__heading">
                CAP300P
              </span>
              <br />
              <br />
              Our unique substitute for Capsicum is enhanced with 3 times the
              potency and a lingering smoky note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Powder. <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.05 - 0.2% in the final product. <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Ideal for spice blends, snack seasonings, and savory bakery
              products. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Hot and spicy aroma with a smoky undertone. <br />
              <span className="scentsectionall__content__info__all__from">
                Advantages :{" "}
              </span>
              Its powder form allows for seamless integration into dry mix
              applications, offering an even distribution of the spicy-smoky
              flavor. Furthermore, for heat-sensitive manufacturing processes,
              it can provide the desired capsaicin punch without the need for
              added liquids, maintaining the integrity of the final product
              texture.
            </div>
            <div className="scentsectionall__content__info__all__withoutbackground">
              <span className="scentsectionall__content__info__all__heading">
                LCT500P
              </span>
              <br />
              <br />
              Our unique Lactones substitute, supercharged with 5 times more
              potency and an added creamy note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Powder. <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.2 - 1% in the final product.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Excellent for dairy products, ice creams, and desserts.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Buttery aroma with a creamy undertone. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              The powder form mixes smoothly with dry ingredients, ensuring a
              homogeneous distribution of flavor—perfect for enhancing
              creaminess in a range of products.
            </div>
            <div className="scentsectionall__content__info__all">
              <span className="scentsectionall__content__info__all__heading">
                VAN400P
              </span>
              <br />
              <br />
              Our proprietary substitute for vanillin is amplified with 4x more
              potency and features a delicate floral note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Powder. <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.1 - 0.5% in final product.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Ideal for bakery, confectionery, and dairy applications.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Sweet, creamy vanilla aroma with a floral hint. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Its powdered form blends effortlessly with dry ingredients,
              ensuring a uniform distribution of the vanilla-floral aroma."
            </div>
            <div className="scentsectionall__content__info__all__withoutbackground">
              <span className="scentsectionall__content__info__all__heading">
                ANIS300OL
              </span>
              <br />
              <br />
              Our unique substitute for Anethole, intensified with 3x more
              potency and a refreshing cool note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Oil-based Liquid.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.05 - 0.25% in final product. <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Perfect for beverage, confectionery, and dessert applications.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Licorice-like aroma with a cool undertone. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Its oil-soluble formulation ensures excellent solubility in
              fat-based food systems, guaranteeing a consistent licorice-cool
              experience.
            </div>
            <div className="scentsectionall__content__info__all">
              <span className="scentsectionall__content__info__all__heading">
                MNT350P
              </span>
              <br />
              <br />
              Our innovative substitute for menthol is boosted with 3.5x more
              potency and features a bright citrus note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Powder.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.1 - 0.5% in final product.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Ideal for chewing gum, candies, and beverages. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Cooling mint aroma with a zesty twist. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Its powdered form can be easily integrated into dry mixes or
              directly added to food systems, providing an even distribution of
              the mint-citrus flavor.
            </div>
            <div className="scentsectionall__content__info__all__withoutbackground">
              <span className="scentsectionall__content__info__all__heading">
                CIT500WL
              </span>
              <br />
              <br />
              Our proprietary substitute for Citral is fortified with 5 times
              the potency and an additional refreshing lime note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Water-based Liquid.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.1 - 0.5% in the final product. <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Excellent for citrus-flavored beverages, confectionery, and
              desserts. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Citrusy aroma with a lime twist. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Its water-based formulation enables easy incorporation into
              water-based systems like beverages, delivering a consistent
              citrus-lime flavor.
            </div>
            <div className="scentsectionall__content__info__all">
              <span className="scentsectionall__content__info__all__heading">
                LNL300OL
              </span>
              <br />
              <br />
              Our exclusive Linalool substitute is supercharged with 3x the
              potency and an extra note of sweet florals. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Oil-based Liquid.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.2 - 1% in final product. <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Ideal for floral and citrus-flavored applications in beverages,
              confectionery, and personal care products. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Fresh floral aroma with a sweet undertone. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Its oil-based formulation is perfect for fat-based systems,
              ensuring an even floral-sweet aroma distribution.
            </div>
            <div className="scentsectionall__content__info__all__withoutbackground">
              <span className="scentsectionall__content__info__all__heading">
                CAP400OL
              </span>
              <br />
              <br />
              Our innovative Capsicum substitute is intensified with 4x the
              potency and features an additional savory note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Oil-based Liquid. <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.05 - 0.3% in final product. <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Suitable for savory applications like sauces, marinades, and meat
              products. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Hot and spicy aroma with a savory edge. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Its oil-based formulation is excellent for fat-based applications,
              providing a consistent spicy-savory flavor experience.
            </div>
            <div className="scentsectionall__content__info__all">
              <span className="scentsectionall__content__info__all__heading">
                EUG350OL
              </span>
              <br />
              <br />
              Our exclusive substitute for Eugenol is supercharged with 3.5x the
              potency and a sweet clove note. <br />
              <br />
              <span className="scentsectionall__content__info__all__from">
                Form :{" "}
              </span>
              Oil-based Liquid. <br />
              <span className="scentsectionall__content__info__all__from">
                Dosage :{" "}
              </span>
              0.05 - 0.3% in final product.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Usage :{" "}
              </span>
              Ideal for bakery, confectionery, and savory applications.
              <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              Spicy, clove-like aroma with a sweet twist. <br />
              <span className="scentsectionall__content__info__all__from">
                Characteristics :{" "}
              </span>
              The oil-soluble nature of EUG350 is perfect for fat-based systems,
              ensuring an even distribution of the spicy-sweet clove aroma.
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
