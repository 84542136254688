import { WebpEssenceLockimg } from "../../assets";
import Footer from "../../components/Footer";
import { addAnimation } from "../../utils/addAnimation";

import { Helmet } from "react-helmet";
export default function EssenceLock() {
  return (
    <>
      <Helmet>
        <title> EssenceLock | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="AFF proudly introduces EssenceLock™, our innovative encapsulation technology. We are not just preserving flavors; we are elevating them, ensuring maximum impact and longevity. Experience the fusion of science and sensory satisfaction with EssenceLock™ - the epitome of flavor encapsulation."
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="AFF proudly introduces EssenceLock™, our innovative encapsulation technology. We are not just preserving flavors; we are elevating them, ensuring maximum impact and longevity. Experience the fusion of science and sensory satisfaction with EssenceLock™ - the epitome of flavor encapsulation."
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/EssenceLock"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/EssenceLock"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="AFF proudly introduces EssenceLock™, our innovative encapsulation technology. We are not just preserving flavors; we are elevating them, ensuring maximum impact and longevity. Experience the fusion of science and sensory satisfaction with EssenceLock™ - the epitome of flavor encapsulation."
        />
        <link rel="canonical" href="https://www.advancedff.com/EssenceLock" />
        <link
          rel="alternate"
          href="https://www.advancedff.com/EssenceLock"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/EssenceLock"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/EssenceLock"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/EssenceLock"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1694773556/advancedff%20Website%20Hero%20Section%20Image/Advancedff_essenceLock_yxbp4u.webp"
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">EssenceLock™</div>
          <div className="about__hero__content__text">
            AFF proudly introduces EssenceLock™, our innovative encapsulation
            technology. We are not just preserving flavors; we are elevating
            them, ensuring maximum impact and longevity. Experience the fusion
            of science and sensory satisfaction with EssenceLock™ - the epitome
            of flavor encapsulation.
          </div>
        </div>
      </section>
      <div className="function__contant__text__wraper">
        <div className="function__contant__text__wraper__heading">
          Taste That Endures
        </div>
        <div className="function__contant__text__wraper__subheading">
          EssenceLock™ by AFF for Flavor Preservation and Controlled Release
        </div>

        <div className="essencelook__container__text">
          {addAnimation(
            "At AFF, we realize the potential of starch-based natural polymers in encapsulation technology. We leverage these properties in EssenceLock™, creating an encapsulation medium that optimizes flavor performance, efficiency, and shelf-life without breaking the bank."
          )}
          <br />
          <br />
          {addAnimation(
            "Our EssenceLock™ technology creates an encapsulating agent characterized by exceptional resistance to oxidation, minimizing losses during spray-drying, and extending your product's shelf-life. More importantly, it guards sensitive volatile components against oxidation, offering you improved product quality and performance. Let's consider a green tea-infused sports drink, for instance. With the inherent astringency and complexity of green tea, delivering a consistent flavor can be a challenge. EssenceLock™ encapsulates the tea flavor, protecting it from degradation and ensuring a gradual release during consumption, presenting consumers with a true-to-life green tea experience that lasts."
          )}
          <br />
          <br />
          {addAnimation(
            "Or think about a spicy BBQ-flavored snack, where the spice kick and smoky undertones must persist despite long shelf life. EssenceLock™ locks in these delicate, volatile compounds, releasing them upon consumption, providing a continual sensory adventure with every bite."
          )}
          <br />
          <br />
          {addAnimation(
            "And how about a vanilla bean almond milk? The volatile vanillin compounds are vulnerable to degradation over time, potentially leading to flavor loss. With EssenceLock™, we encapsulate these volatile compounds, ensuring a steady, controlled release of the sweet, creamy vanilla flavor in every sip, even after extended storage."
          )}
          <br />
          <br />
          {addAnimation(
            "EssenceLock™ is more than an encapsulation technology; it is a commitment to flavor integrity, controlled release, and enhanced organoleptic impact. At AFF, we develop customized solutions that set your products apart."
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
