import { WebpFreshsealmg, WebpStepcardboxmg } from "../../assets";

import Footer from "../../components/Footer";
import { addAnimation } from "../../utils/addAnimation";

export default function Freshseal() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpFreshsealmg}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">FreshSeal™</div>
        </div>
      </section>
      <section className="FreshSeal__main__container">
        <div className="FreshSeal__stages__card__contraier">
          <div className="FreshSeal__stages__card__wraper">
            <div className="FreshSeal__stages__card__wraper__heading">
              High Barrier Technology
            </div>
            <div className="FreshSeal__stages__card__wraper__subheading">
              {addAnimation(" A Shield Against Time")}
            </div>
            <div className="FreshSeal__stages__card__wraper__contant">
              {addAnimation(
                "Our innovative packaging solution employs a multi-layer High Barrier Technology, a formidable fortress that safeguards the freshness of our products. This is not your everyday food packaging. The materials used are Food Grade, meticulously selected and rigorously tested to ensure they can withstand the unique challenges of storing food flavorings. This is science in action, material science to be precise. The composition and structure of the materials used in our High Barrier Technology play a critical role in its functionality. Each layer serves a specific purpose, working in unison to create a robust barrier against environmental factors that threaten the quality of our products."
              )}
            </div>
            <div className="FreshSeal__stages__card__wraper__img">
              <img
                loading="lazy"
                src={WebpStepcardboxmg}
                alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-step"
              />
            </div>
          </div>
          <div className="FreshSeal__stages__card__wraper">
            <div className="FreshSeal__stages__card__wraper__heading">
              FreshSeal™
            </div>
            <div className="FreshSeal__stages__card__wraper__subheading">
              {addAnimation(
                " A Vacuum Sealing System that Outsmarts Oxidation"
              )}
            </div>
            <div className="FreshSeal__stages__card__wraper__contant">
              {addAnimation(
                "Our proprietary Vacuum Sealing system, takes product preservation a step further. This is not simply about sealing a package; its about creating a consistent, dependable seal that protects the integrity of our products. The brilliance of this method is in its effectiveness and simplicity: by removing air from the packaging thoroughly, we minimize the presence of oxygen."
              )}
              <br />
              <br />
              {addAnimation(
                "Oxygen, despite being vital for life, is a known adversary when it comes to food preservation. This ubiquitous element is notorious for accelerating the degradation of powder products. But how does this happen? Its all about oxidation, a chemical reaction that leads to flavor deterioration, loss of potency, and compromised quality. Through the use of our proprietary Vacuum Sealing system, we effectively inhibit oxidation, outsmarting nature and preserving the sensory delight our products promise."
              )}
              <br />
              <br />
              {addAnimation(
                "At Advanced Flavors & Fragrances (AFF), we intertwine the art of flavor creation with the science of preservation. We understand that in order to deliver a symphony of taste and aroma, we must first protect the conductors - the precious flavor and fragrance compounds in our products. And we do so with a potent blend of cutting-edge technology and rigorous science."
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
