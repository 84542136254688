import {
  WebpCustomfvr,
  WebpCustomfvr1,
  WebpCustomfvr2,
  WebpCustomfvr3,
} from "../../assets";

import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
import { addAnimation } from "../../utils/addAnimation";

export default function CustomFlavor() {
  return (
    <>
      {" "}
      <Helmet>
        <title> CustomFlavor | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="Your unique product calls for a unique flavor. At AFF, we paint with a palette of taste, composing a symphony of sensory delight, tailor-made for your product. With the entire flavor engineered in-house, we masterfully orchestrate your product's flavor profile - from the initial top note to the lingering aftertaste.
"
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="Your unique product calls for a unique flavor. At AFF, we paint with a palette of taste, composing a symphony of sensory delight, tailor-made for your product. With the entire flavor engineered in-house, we masterfully orchestrate your product's flavor profile - from the initial top note to the lingering aftertaste.
"
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/CustomFlavor"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/CustomFlavor"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="Your unique product calls for a unique flavor. At AFF, we paint with a palette of taste, composing a symphony of sensory delight, tailor-made for your product. With the entire flavor engineered in-house, we masterfully orchestrate your product's flavor profile - from the initial top note to the lingering aftertaste.
"
        />
        <link rel="canonical" href="https://www.advancedff.com/CustomFlavor" />
        <link
          rel="alternate"
          href="https://www.advancedff.com/CustomFlavor"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/CustomFlavor"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/CustomFlavor"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/CustomFlavor"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpCustomfvr}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">
            Custom Flavor Solutions
          </div>
          <div className="about__hero__content__text">
            Your unique product calls for a unique flavor. At AFF, we paint with
            a palette of taste, composing a symphony of sensory delight,
            tailor-made for your product. With the entire flavor engineered
            in-house, we masterfully orchestrate your product's flavor profile -
            from the initial top note to the lingering aftertaste.
          </div>
        </div>
      </section>
      <section className="custom__flavor__main__container">
        <CustomFlavorCard
          img={WebpCustomfvr1}
          heading="Mastering the Art of Stability"
          text="We at AFF recognize the challenges R&D teams face when trying to achieve
        a distinctive flavor profile while considering application requirements.
        Ingredient interactions, solubility, stability under different pH
        levels, temperatures, and humidity are among the many aspects we
        meticulously factor in while creating your custom flavor solution."
        />
        <CustomFlavorCard
          heading="Solubility"
          img={WebpCustomfvr2}
          text="Take the development of flavors for oil-based applications, for instance. We consider how lipophilic flavor components will interact with the fatty matrix of your product, crafting flavors that remain stable and impactful even in such demanding applications. On the other hand, when working with high-protein products like protein bars, we know certain proteins may impart off-notes or exhibit undesirable organoleptic properties. In such scenarios, we take a two-pronged approach: developing flavors that enhance the appealing taste notes and simultaneously mitigating the less desirable ones."
        />
        <CustomFlavorCard
          img={WebpCustomfvr3}
          heading="Sensation"
          text="And in the domain of baked goods, temperature stability is a common challenge. We engineer flavors to withstand high-temperature processing, ensuring that your product retains its desired flavor profile throughout the baking process and well beyond. We are also mindful of the growing interest in 'clean-label' products. To that end, we can offer natural, organic compliant, and non-GMO flavors, making sure your product meets both taste and labeling expectations.
          With AFF's Custom Flavor Solutions, you get a partner who understands and addresses your unique product challenges head-on, yielding flavors that harmonize with your product and captivate your consumers. "
        />
      </section>
      <Footer />
    </>
  );
}

function CustomFlavorCard({ img, text, heading }) {
  return (
    <div className="custom__flavor__main__container__waraper">
      <div className="custom__flavor__card__wraper__img">
        <img
          loading="lazy"
          className="custom__flavor__card__img"
          src={img}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-flavor"
        />
      </div>

      <div className="custom__flavor__card__wraper__contant">
        <div className="custom__flavor__card__wraper__contant__heading">
          {addAnimation(heading)}
        </div>
        <div className="custom__flavor__card__wraper__contant__text">
          {addAnimation(text)}
        </div>
      </div>
    </div>
  );
}
