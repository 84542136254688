import "aos/dist/aos.css";

import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import AOS from "aos";
import Header from "../components/Header";
import Loading from "./Loading";
import posthog from "posthog-js";

posthog.init("phc_Koa3heQabq5vdlfYXeo4WiBvLGAleSy7dyo0a8Fs2oS", {
  api_host: "https://app.posthog.com",
});

AOS.init();
export default () => {
  const [cookie, setCookie] = useState(localStorage.getItem("cookie"));
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    posthog.capture("my event", { property: "value" });
  }, [location.pathname]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {location.pathname === "/404" ? (
        <Outlet />
      ) : (
        <>
          <Header isOnVerify={location.pathname === "/verify"} />
          <Outlet />
          {window.innerWidth > 768 && cookie === null && (
            <div className="cooke__container">
              <div className="cooke__container__text">
                Continued use of this site means you consent to our {""}
                <Link className="cooke__container__text_link" to="/Privacy">
                  privacy
                </Link>{" "}
                policy.
              </div>
              <div
                className="cooke__container__btn__entry__one"
                onClick={() => {
                  setCookie(true);
                  localStorage.setItem("cookie", true);
                }}
              >
                Accept All
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
