import {
  PngScentsolutionfin,
  WebpScentcardimg1,
  WebpScentcardimg2,
  WebpScentcardimg3,
  WebpScentcardimg4,
  WebpScentcardimg5,
  WebpScentsolution,
} from "../../assets";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

export default function Scent() {
  return (
    <>
      <Helmet>
        <title> Scent | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="At AFF, we do not just concoct aromas; we curate olfactory identities. For instance, when designing a signature scent for an avant-garde men’s grooming line, we draw on our extensive knowledge of aroma chemistry and market trends. We tailor fragrances to withstand varying pH levels, react positively with other ingredients, and importantly, resonate with your target demographic.
          "
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="At AFF, we do not just concoct aromas; we curate olfactory identities. For instance, when designing a signature scent for an avant-garde men’s grooming line, we draw on our extensive knowledge of aroma chemistry and market trends. We tailor fragrances to withstand varying pH levels, react positively with other ingredients, and importantly, resonate with your target demographic.
"
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/taste/scent"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/taste/scent"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="At AFF, we do not just concoct aromas; we curate olfactory identities. For instance, when designing a signature scent for an avant-garde men’s grooming line, we draw on our extensive knowledge of aroma chemistry and market trends. We tailor fragrances to withstand varying pH levels, react positively with other ingredients, and importantly, resonate with your target demographic.
"
        />
        <link rel="canonical" href="https://www.advancedff.com/taste/scent" />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/scent"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/scent"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/scent"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/scent"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={PngScentsolutionfin}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Scent Solutions</div>
          <div className="about__hero__content__text">
            At AFF, we do not just concoct aromas; we curate olfactory
            identities. For instance, when designing a signature scent for an
            avant-garde men’s grooming line, we draw on our extensive knowledge
            of aroma chemistry and market trends. We tailor fragrances to
            withstand varying pH levels, react positively with other
            ingredients, and importantly, resonate with your target demographic.
          </div>
        </div>
      </section>
      <section className="scentsectionall">
        <div className="scentsectionall__content__main">
          <div className="scentsectionall__content__cards">
            <div className="scentsectionall__content__card">
              <a
                href="/scent/personalcare"
                className="scentsectionall__content__card__img"
              >
                <img
                  loading="lazy"
                  src={WebpScentcardimg1}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-scentcardimg1"
                />
              </a>
              <div className="scentsectionall__content__card__heading">
                Personal Care
              </div>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/scent/personalcare"
                className="scentsectionall__content__card__button"
              >
                Learn more
              </Link>
            </div>
            <div className="scentsectionall__content__card">
              <a
                href="/scent/finecare"
                className="scentsectionall__content__card__img2"
              >
                <img
                  loading="lazy"
                  src={WebpScentcardimg2}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-scentcardimg1"
                />
              </a>
              <div className="scentsectionall__content__card__heading">
                Fine Fragrances
              </div>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/scent/finecare"
                className="scentsectionall__content__card__button"
              >
                Learn more
              </Link>
            </div>
          </div>
          <div className="scentsectionall__content__cards">
            <div className="scentsectionall__content__card">
              <a
                href="/scent/homecare"
                className="scentsectionall__content__card__img3"
              >
                <img
                  loading="lazy"
                  src={WebpScentcardimg3}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-scentcardimg1"
                />
              </a>
              <div className="scentsectionall__content__card__heading">
                Home Care
              </div>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/scent/homecare"
                className="scentsectionall__content__card__button"
              >
                Learn more
              </Link>
            </div>
            <div className="scentsectionall__content__card">
              <a
                href="/scent/aircare"
                className="scentsectionall__content__card__img3"
              >
                <img
                  loading="lazy"
                  src={WebpScentcardimg4}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-scentcardimg1"
                />
              </a>
              <div className="scentsectionall__content__card__heading">
                Air Care
              </div>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/scent/aircare"
                className="scentsectionall__content__card__button"
              >
                Learn more
              </Link>
            </div>
            <div className="scentsectionall__content__card">
              <a
                href="/scent/cosmetics"
                className="scentsectionall__content__card__img3"
              >
                <img
                  loading="lazy"
                  src={WebpScentcardimg5}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-scentcardimg1"
                />
              </a>
              <div className="scentsectionall__content__card__heading">
                Cosmetics
              </div>
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                to="/scent/cosmetics"
                className="scentsectionall__content__card__button"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
