import {
  WebpCustom,
  WebpEssenceLock,
  WebpFunctional,
  WebpNatural,
} from "../../assets";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { WebpTasteSolutionsBg } from "../../assets";
import Footer from "../../components/Footer";

export default function Taste() {
  return (
    <>
      <Helmet>
        <title> Taste | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="Embark with us on an epicurean expedition, where the past meets the present, where science meets artistry, where the ordinary becomes extraordinary.
          "
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="Embark with us on an epicurean expedition, where the past meets the present, where science meets artistry, where the ordinary becomes extraordinary."
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta name="twitter:url" content="https://www.advancedff.com/taste" />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:url" content="https://www.advancedff.com/taste" />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="Embark with us on an epicurean expedition, where the past meets the present, where science meets artistry, where the ordinary becomes extraordinary."
        />
        <link rel="canonical" href="https://www.advancedff.com/taste" />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1694773556/advancedff%20Website%20Hero%20Section%20Image/Advancedff_taste_kfrwxh.webp"
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Taste Solutions</div>
          <div className="about__hero__content__text">
            Embark with us on an epicurean expedition, where the past meets the
            present, where <span>science</span> meets <span>artistry</span>,
            where the ordinary becomes
            <span> extraordinary</span>.
          </div>
        </div>
      </section>
      <section className="taste__flavor__content__main">
        <TasteFalvor
          link="/taste/customflavor"
          img={WebpCustom}
          text="Custom Flavor Solutions"
          svg={
            <svg
              width="25"
              height="23"
              viewBox="0 0 31 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.3287 12.7423C30.9145 12.1565 30.9145 11.2068 30.3287 10.621L20.7828 1.07504C20.197 0.489251 19.2473 0.489251 18.6615 1.07504C18.0757 1.66082 18.0757 2.61057 18.6615 3.19636L27.1467 11.6816L18.6615 20.1669C18.0757 20.7527 18.0757 21.7025 18.6615 22.2882C19.2473 22.874 20.197 22.874 20.7828 22.2882L30.3287 12.7423ZM0.611817 13.1816L29.2681 13.1816L29.2681 10.1816L0.611816 10.1816L0.611817 13.1816Z"
                fill="#D64C00"
              />
            </svg>
          }
        />
        <TasteFalvor
          link="/taste/naturalflavor"
          img={WebpNatural}
          text="Natural Flavor Solutions"
          svg={
            <svg
              width="25"
              height="23"
              viewBox="0 0 31 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.3287 12.7423C30.9145 12.1565 30.9145 11.2068 30.3287 10.621L20.7828 1.07504C20.197 0.489251 19.2473 0.489251 18.6615 1.07504C18.0757 1.66082 18.0757 2.61057 18.6615 3.19636L27.1467 11.6816L18.6615 20.1669C18.0757 20.7527 18.0757 21.7025 18.6615 22.2882C19.2473 22.874 20.197 22.874 20.7828 22.2882L30.3287 12.7423ZM0.611817 13.1816L29.2681 13.1816L29.2681 10.1816L0.611816 10.1816L0.611817 13.1816Z"
                fill="#D64C00"
              />
            </svg>
          }
        />
        <TasteFalvor
          link="/taste/functionalflavor"
          img={WebpFunctional}
          text="Functional Flavor Application"
          svg={
            <svg
              width="25"
              height="23"
              viewBox="0 0 31 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.3287 12.7423C30.9145 12.1565 30.9145 11.2068 30.3287 10.621L20.7828 1.07504C20.197 0.489251 19.2473 0.489251 18.6615 1.07504C18.0757 1.66082 18.0757 2.61057 18.6615 3.19636L27.1467 11.6816L18.6615 20.1669C18.0757 20.7527 18.0757 21.7025 18.6615 22.2882C19.2473 22.874 20.197 22.874 20.7828 22.2882L30.3287 12.7423ZM0.611817 13.1816L29.2681 13.1816L29.2681 10.1816L0.611816 10.1816L0.611817 13.1816Z"
                fill="#D64C00"
              />
            </svg>
          }
        />
        <TasteFalvor
          link="/taste/essenceLock"
          img={WebpEssenceLock}
          text="EssenceLock™"
          svg={
            <svg
              width="25"
              height="23"
              viewBox="0 0 31 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.3287 12.7423C30.9145 12.1565 30.9145 11.2068 30.3287 10.621L20.7828 1.07504C20.197 0.489251 19.2473 0.489251 18.6615 1.07504C18.0757 1.66082 18.0757 2.61057 18.6615 3.19636L27.1467 11.6816L18.6615 20.1669C18.0757 20.7527 18.0757 21.7025 18.6615 22.2882C19.2473 22.874 20.197 22.874 20.7828 22.2882L30.3287 12.7423ZM0.611817 13.1816L29.2681 13.1816L29.2681 10.1816L0.611816 10.1816L0.611817 13.1816Z"
                fill="#D64C00"
              />
            </svg>
          }
        />
      </section>
      <Footer />
    </>
  );
}

function TasteFalvor({ img, text, svg, link }) {
  return (
    <Link
      onClick={() => window.scrollTo(0, 0)}
      to={link}
      className="taste__flavor__content__card"
    >
      <div className="taste__flavor__content__card__img">
        <img
          loading="lazy"
          src={img}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-card"
        />
      </div>
      <div className="taste__flavor__card__text__container">
        <div className="taste__flavor__card__text__heading">{text}</div>
        <div className="taste__flavor__card__text__svg">{svg}</div>
      </div>
    </Link>
  );
}
