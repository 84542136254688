import {
  WebpFuncrd1,
  WebpFuncrd2,
  WebpFuncrd3,
  WebpFuncrd4,
  WebpFuncrd5,
  WebpFuncrd6,
  WebpFuncrd7,
  WebpFunctionalFlavor,
} from "../../assets";
import Footer from "../../components/Footer";

import { addAnimation } from "../../utils/addAnimation";

export default function FunctionalFlavor() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1694773556/advancedff%20Website%20Hero%20Section%20Image/Advancedff_functionalflavor_ywjlmw.webp"
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">
            Functional Flavor Application
          </div>
          <div className="about__hero__content__text">
            Flavor with a mission. At AFF, we are pioneering more than just
            taste delivery – we are addressing real-world challenges within your
            product matrix. With every flavor formulated 100% in-house, we have
            got the reins from the primary volatile to the final ester. We are
            not just your flavor provider but also your co-pilot in product
            development.
          </div>
        </div>
      </section>
      <div
        className="function__contant__text__wraper"
        style={{
          paddingBottom: "0px",
        }}
      >
        <div className="function__contant__text__wraper__heading">
          {addAnimation("  Flavoring Wellness with Purpose")}
        </div>
        <div className="function__contant__text__wraper__subheading">
          {addAnimation(
            " AFF's Delicious Approach to Health and R&D Excellence",
          )}
        </div>
      </div>
      <div className="function__contant__text__footer">
        {addAnimation(
          "We create all our flavor formulations 100% in-house, giving us unrivaled control over their attributes, ensuring optimal solubility, and allowing us to modulate them as needed. This mastery over flavor characteristics enables us to tailor our offerings to your specific challenges.",
        )}
        <br />
        <br />
        {addAnimation(
          "With AFF's Functional Flavor Applications, you'll leverage the power of flavors that are as functional as they are palatable, crafted by a partner who truly comprehends your needs.",
        )}
      </div>
      <section className="function__cards">
        <div className="function__card__main__container">
          <FunctionFlavor
            img={WebpFuncrd1}
            heading="Odor Masking"
            subheading="Some ingredients emit pronounced off-notes. Take fish oil supplements; we have devised specific masking flavors, employing flavor modulators to neutralize those fishy notes, enhancing the overall organoleptic experience."
          />
          <FunctionFlavor
            img={WebpFuncrd2}
            heading="Salt Reduction"
            subheading="Sodium reduction in products like potato chips can seem insurmountable. However, using our in-house developed salt-enhancing flavors, we have enabled brands to slash sodium content while preserving that savory gustatory punch consumers love."
          />
          <FunctionFlavor
            img={WebpFuncrd3}
            heading="Sugar Reduction"
            subheading="Balancing sweetness in reduced-sugar beverages is a common conundrum. Our sugar-enhancing flavors, meticulously engineered to interact optimally with the product's matrix, have empowered brands to significantly reduce sugar content in carbonated drinks while preserving a complete sweetness profile."
          />
          <FunctionFlavor
            img={WebpFuncrd4}
            heading="Bitterness Blocking"
            subheading="Ingredients like whey protein can introduce a bitter taste in sports nutrition products. Our bitterness-blocking flavors harmonize with such ingredients, reducing their intensity and enhancing overall taste acceptance."
          />
          <FunctionFlavor
            img={WebpFuncrd5}
            heading="Texture Enhancement"
            subheading="Achieving the right mouthfeel in plant-based dairy alternatives is a challenge. We have crafted flavors that augment the perception of creaminess, eliciting a true dairy-like experience in products like plant-based cheese."
          />
          <FunctionFlavor
            img={WebpFuncrd6}
            heading="Heat Stability"
            subheading="Ensuring flavor integrity in high-temperature processed goods, such as baked snacks, can be demanding. With our heat-stable flavors, we have guaranteed a consistent flavor profile, regardless of processing conditions."
          />
          <FunctionFlavor
            img={WebpFuncrd7}
            heading="Shelf-life Extension"
            subheading="Maintaining flavor impact in long shelf-life products like canned soups can be daunting. We have innovatively engineered flavors that preserve their organoleptic impact throughout the product's shelf-life."
          />
        </div>
        <div className="function__contant__text__footer"></div>
      </section>
      <Footer />
    </>
  );
}

function FunctionFlavor({ img, heading, subheading }) {
  return (
    <div className="function__card__wraper">
      <div className="function__card__wraper__img">
        <img
          loading="lazy"
          src={img}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-flavor"
        />
      </div>
      <div className="function__card__wraper__contant">
        <div className="function__card__wraper__heading">
          {addAnimation(heading)}
        </div>
        <div className="function__card__wraper__subheading">
          {addAnimation(subheading)}
        </div>
      </div>
    </div>
  );
}
