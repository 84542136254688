import {
  PngAboutusGlobalPresence1,
  PngAboutuspage,
  PngFacilitiesart,
  SvgGuarantee,
  SvgGuaranteeHover,
  SvgJoin,
  SvgJoinHover,
  WebpCertificationsimg,
} from "../assets";

import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { addAnimation } from "../utils/addAnimation";

export default function Aboutus() {
  return (
    <>
      {" "}
      <Helmet>
        <title> About Us | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="Founded in 2018, About Us Advanced Flavors & Fragrances (AFF) emerged as a rare entrant in a market traditionally monopolized by multinational corporations. We are a data-driven Flavor & Fragrance company led by a cadre of seasoned Flavorists and Perfumers, seeking to redefine the sensory landscape. With over a century of combined experience and State-of-the-Art technological capabilities, we have developed Flavors and Fragrances that are as functional as they are delightful, touching lives around the world.
          "
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="Founded in 2018, About Us Advanced Flavors & Fragrances (AFF) emerged as a rare entrant in a market traditionally monopolized by multinational corporations. We are a data-driven Flavor & Fragrance company led by a cadre of seasoned Flavorists and Perfumers, seeking to redefine the sensory landscape. With over a century of combined experience and State-of-the-Art technological capabilities, we have developed Flavors and Fragrances that are as functional as they are delightful, touching lives around the world.
"
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/who-we-are"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/who-we-are"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="Founded in 2018, About Us Advanced Flavors & Fragrances (AFF) emerged as a rare entrant in a market traditionally monopolized by multinational corporations. We are a data-driven Flavor & Fragrance company led by a cadre of seasoned Flavorists and Perfumers, seeking to redefine the sensory landscape. With over a century of combined experience and State-of-the-Art technological capabilities, we have developed Flavors and Fragrances that are as functional as they are delightful, touching lives around the world.
"
        />
        <link rel="canonical" href="https://www.advancedff.com/who-we-are" />
        <link
          rel="alternate"
          href="https://www.advancedff.com/who-we-are"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/who-we-are"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/who-we-are"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/who-we-are"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={PngAboutuspage}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Who We Are</div>
          <div className="about__hero__content__text">
            Founded in 2018, About Us Advanced Flavors & Fragrances (AFF)
            emerged as a rare entrant in a market traditionally monopolized by
            multinational corporations. We are a data-driven Flavor & Fragrance
            company led by a cadre of seasoned Flavorists and Perfumers, seeking
            to redefine the sensory landscape. With over a century of combined
            experience and State-of-the-Art technological capabilities, we have
            developed Flavors and Fragrances that are as functional as they are
            delightful, touching lives around the world.
          </div>
        </div>
      </section>{" "}
      <section className="natural__flavor__container">
        <div className="natural__flavor__container__wraper">
          <div className="natural__flavor__container__wraper__contant">
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation(" Our Mission  ")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "We are not just another flavor & fragrance house. Our mission is to bring creativity and innovation into every aroma and taste, surprising and delighting the senses. We empower food creators by providing a versatile range of customized flavors, catering to needs ranging from trial batches to full container loads. Even our standard flavors are designed to work in broad applications, saving you both time and money.         "
              )}{" "}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading">
              {addAnimation(" Our Team ")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "Our team, boasting a rich heritage in the flavor and fragrance industry, brings together decades of sensory expertise. Some of our Flavorists were pioneers in the industry with half a century of sensory experience individually. Each one of them wanted to be part of the future that they devoted their lives to creating, and that’s why AFF was born."
              )}
            </div>
            <br />
            <div className="natural__flavor__container__wraper__contant__heading__sub  natural__flavor__container__wraper__contant__heading__sub__border">
              At AFF, talent has no age limit. We are continually expanding our
              team with exceptional individuals, whether they are early-career
              professionals bringing fresh perspectives or seasoned experts,
              including those who are retired but still have invaluable wisdom
              and connections to offer. If you believe in our vision, we would
              like to hear from you.{" "}
              <div className="natural__flavor__container__wraper__contant__heading__sub__border__line">
                <hr />{" "}
              </div>{" "}
              <div className="natural__flavor__container__wraper__contant__heading__sub  ">
                Please reach out to us at{" "}
                <span style={{ color: "#E35303" }}>
                  careers@advancedff.com.
                </span>
              </div>
            </div>{" "}
            <div className="natural__flavor__container__wraper__contant__heading__sub natural__flavor__container__wraper__contant__heading__sub__higlite">
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "Our approach has created a pool of vast knowledge, experience and expertise that continuously grows every single day. We are equipped to meet the most nuanced demands of our clients, helping their products stand out in a competitive marketplace.               "
              )}
              <br />
            </div>
          </div>
        </div>
      </section>
      <section className="Facilitiesartsection">
        <div className="Facilitiesartsection__content">
          <img
            src={PngFacilitiesart}
            alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Facilitiesart"
          />
          <div className="Facilitiesartsection__content__overlay">
            <div className="Facilitiesartsection__content__overlay__content">
              <div className="Facilitiesartsection__content__overlay__heading">
                {addAnimation("State-of-the-Art Facilities")}
              </div>
              <div className="Facilitiesartsection__content__overlay__info">
                {addAnimation(
                  "Situated in JTC Food Hub @ Senoko, the brainchild of the Singapore Food Manufacturers' Association (SFMA) and Singapore Manufacturing Federation (SMF) together with Jurong Town Corporation (JTC), to bolster the growth of the food sector in Singapore, is home to many world-famous brands. Our facilities are nothing short of cutting-edge. Here, State-of-the-Art equipment like spray dryers, automatic flavor compounders, and Gas Chromatography-Mass Spectrometers (GC-MS) are standard, ensuring unparalleled quality from raw material to finished product. We are able to accommodate loading and shipping 4 x 40 footer containers at the same time."
                )}
              </div>
              <div className="Facilitiesartsection__content__overlay__info">
                {addAnimation(
                  "Our facilities fully comply with Singapore Food Agency’s requirements and goes above and beyond to ensure that the products produced are not only food-safe and prevents sensory contamination between different categories of products. We use only the finest Food-Grade and Pharmaceutical Grade “USP” Raw Materials for our products."
                )}
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section className="natural__flavor__container">
        <div className="natural__flavor__container__wraper">
          <div className="natural__flavor__container__wraper__contant">
            <div
              style={{ fontWeight: "bold" }}
              className="natural__flavor__container__wraper__contant__heading"
            >
              {addAnimation(" Unique Service Offerings ")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "What truly sets us apart is our ability to service unique needs. We specialize in:"
              )}{" "}
              <br />
            </div>{" "}
            <br />
            <div className="natural__flavor__container__wraper__contant__heading__head">
              {addAnimation(" Small Quantity Continuous Servicing ")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "Our advanced equipment can handle any order size, offering the same level of quality and attention to both small and large-scale productions. Most importantly, is our willingness to support our customers in small batches for a long period of time as they build up their market. We are not only willing to invest in your success, but we are willing to do it over the long-term, as we understand it takes time to build up a market."
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__head">
              {addAnimation(" Customization ")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "We act as an extension of your R&D team, ensuring that our flavors are perfectly integrated into your product applications. With our diverse team, we can tailor to local tastes in not only each country, but each region within a country.               "
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__head">
              {addAnimation(" Integrated Solutions ")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "Leveraging our expertise, our R&D can create holistic solutions that goes beyond merely flavors, harmonizing with other raw materials, helping you lower costs. We can help with Functional Taste Solutions that can allow your products to meet regulatory requirements or dietary requirements, natural or identical-to-natural.               "
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "We also share our cost advantages from economies of scale with small Food Manufacturers, helping them lower their costs through the efforts of our world-class procurement team and the global supplier network that they have built that is capable of sourcing any raw materials. "
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub subheadingbold">
              {addAnimation(
                "Consider us your personal procurement and R&D department."
              )}
              <br />
              <br />
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__head">
              {addAnimation(" Unwavering Commitment to Quality ")}
            </div>
            <div className="natural__flavor__container__wraper__contant__heading__sub">
              {addAnimation(
                "Quality is not just a buzzword for us; it’s an uncompromising commitment. Every raw material undergoes rigorous Quality Control checks to ensure that its sensory profile meets our high standards.               "
              )}
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <section className="aboutus__global__presence">
        <div className="aboutus__global__presence__content">
          <div className="aboutus__global__presence__content__left">
            <img
              src={PngAboutusGlobalPresence1}
              alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Globe"
            />
          </div>
          <div className="aboutus__global__presence__content__right">
            <div className="Facilitiesartsection__content__overlay__heading">
              {addAnimation("Global Presence")}
            </div>
            <div className="Facilitiesartsection__content__overlay__info">
              {addAnimation(
                "Within a short span of 5 years, our flavors and fragrances have crossed borders, reaching Singapore, Malaysia, Indonesia, Thailand, Vietnam, Myanmar, Philippines, Taiwan, South Korea, China, Pakistan, Cambodia, and India amongst others. Our clientele also includes several esteemed companies from Europe and America. We serve small local bakeries to Fortune 500 MNCs alike, just the same, no discrimination."
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="certifications__section">
        <div className="certifications__section__content">
          <div className="certifications__section__content__left">
            <div className="certifications__section__content__left__heading">
              {addAnimation("Certifications")}
            </div>
            <div className="certifications__section__content__left__heading__sub">
              {addAnimation(
                "We operate under stringent quality guidelines; our Food Manufacturing Facility is certified by the Singapore Food Agency (SFA) and we hold the Halal Certification from MUIS Singapore that is internationally recognized. However, we go above and beyond what is required to ensure we produce quality goods. Whatever requirements you may have, our regulatory team will go out of the way to meet them."
              )}
            </div>
          </div>
          <div className="certifications__section__content__left">
            <img
              src={WebpCertificationsimg}
              alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Certificationss"
              className="certifications__section__content__right"
            />
          </div>
        </div>
      </section>
      <section className="aboutus___card__Section">
        <div className="aboutus___card__Section__content">
          <div className="aboutus___card__Section__content__cards">
            <div className="aboutus___card__Section__content__card">
              <div className="aboutus___card__Section__content__card__icon">
                <img
                  src={SvgJoin}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-aboutuscardicon"
                />
              </div>
              <div className="aboutus___card__Section__content__card__icon__hover">
                <img
                  src={SvgJoinHover}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-aboutuscardicon"
                />
              </div>
              <div className="aboutus___card__Section__content__card__heading">
                {addAnimation("Join Us")}
              </div>
              <div
                className="Facilitiesartsection__content__overlay__info"
                style={{ wordBreak: "break-all", textAlign: "left" }}
              >
                {addAnimation(
                  "We are in constant pursuit of partnerships and exceptional talent who share our vision. If you are a company that shares the same vision, please email us @ mergersandacquisitions@advancedff.com."
                )}{" "}
                {addAnimation(
                  "For distributor and partnership opportunities, please email us at distribution@advancedff.com."
                )}{" "}
              </div>
            </div>
            <div className="aboutus___card__Section__content__card">
              <div className="aboutus___card__Section__content__card__icon">
                <img
                  src={SvgGuarantee}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-aboutuscardicon"
                />
              </div>
              <div className="aboutus___card__Section__content__card__icon__hover">
                <img
                  src={SvgGuaranteeHover}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  About Us Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-aboutuscardicon"
                />
              </div>
              <div className="aboutus___card__Section__content__card__heading">
                {addAnimation("Our Guarantee")}
              </div>
              <div
                className="Facilitiesartsection__content__overlay__info"
                style={{ textAlign: "left" }}
              >
                {addAnimation(
                  "Our Guarantee is simple. If our product is not to specifications, we will replace it at no cost to you. This illustrates our confidence in our products. To learn more, visit Our Guarantee section."
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
