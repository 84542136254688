import { addAnimation } from "../utils/addAnimation";

export default function PointsCard({ heading, text }) {
  return (
    <>
      <div className="scentsectionall__content__point">
        <div className="scentsectionall__content__points__heading">
          {addAnimation(heading)}
        </div>
        <div className="scentsectionall__content__points__text">
          {addAnimation(text)}
        </div>
      </div>
    </>
  );
}
