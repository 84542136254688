import { WebpHeaderImage } from "../assets";

let megaMenuList = [
  {
    title: "Taste Solutions",
    link: "taste",
    children: [
      {
        title: "Custom Flavor Solutions",
        titleLink: "taste/customflavor",
        img: WebpHeaderImage,
        content:
          "Your unique product calls for a unique flavor. At AFF, we paint with a palette of taste, composing a symphony of sensory delight, tailor-made for your product. With the entire flavor engineered in-house, we masterfully orchestrate your product's flavor profile - from the initial top note to the lingering aftertaste.",
        contentLink: "taste/customflavor",
      },
      {
        title: "Natural Flavor Solutions",
        titleLink: "taste/naturalflavor",
        img: WebpHeaderImage,
        content:
          "At AFF, we understand the market's ongoing shift towards natural ingredients, as well as the challenges it presents. We see the complexity of natural flavors, the nuances in their profiles, and the sensitivity they have towards various processing conditions and matrices. And we embrace it all, turning these challenges into opportunities to create compelling natural flavor solutions.",
        contentLink: "taste/naturalflavor",
      },
      {
        title: "Functional Flavor Application",
        titleLink: "taste/functionalflavor",
        img: WebpHeaderImage,
        content:
          "Flavor with a mission. At AFF, we are pioneering in more than just taste delivery - we are addressing real-world challenges within your product matrix. With every flavor formulated 100% in-house, we have got the reins from the primary volatile to the final ester. We are not just your flavor provider, but your co-pilot in product development.",
        contentLink: "taste/functionalflavor",
      },
      {
        title: "EssenceLock",
        titleLink: "taste/essencelock",
        img: WebpHeaderImage,
        content:
          "AFF proudly introduces EssenceLock™, our innovative encapsulation technology. We are not just preserving flavors; we are elevating them, ensuring maximum impact and longevity. Experience the fusion of science and sensory satisfaction with EssenceLock™ - the epitome of flavor encapsulation.",
        contentLink: "taste/essencelock",
      },
      {
        title: "Speciality Aroma Chemicals",
        titleLink: "taste/specialty",
        img: WebpHeaderImage,
        content:
          "In the realm of aroma innovation at AFF, we are reshaping the ordinary to offer you an exceptional collection of Specialty Aroma Chemicals. Designed with the specific needs of Flavor and Fragrance composition in mind, these are not just substitutes - they are superior enhancements.",
        contentLink: "taste/specialty",
      },
      {
        title: "Products",
        titleLink: "taste/products",
        img: WebpHeaderImage,
        content:
          "We offer a wide range of products, from natural flavors to functional flavor solutions, to meet your needs. Our products are designed to be easily integrated into your processes, and we are always happy to create a tailor-made solution for your unique application.",
        contentLink: "taste/products",
      },
    ],
  },
  {
    title: "Scent Solutions",
    link: "scent",
    children: [
      {
        title: "Personal Care",
        titleLink: "scent/personalcare",
        img: WebpHeaderImage,
        content:
          "Creating a fragrance for a personal care product is a thoughtful process that intertwines science and art. Consider a body lotion designed for nighttime use. We delve deep into the science of calming olfactory molecules and skin-compatible fixatives, and align it with consumer insights for night-time skincare routines. The result? An aroma that whispers relaxation and complements the soothing texture of the lotion.",
        contentLink: "scent/personalcare",
      },
      {
        title: "Home Care",
        titleLink: "scent/homecare",
        img: WebpHeaderImage,
        content:
          "Home care fragrances need to satisfy two key elements: functional performance and emotional satisfaction. With a lemon-scented floor cleaner, for instance, we ensure the fragrance stands up against alkaline environments and simultaneously enhances user experience. The aroma does not merely mask the product's chemical profile; it delivers an uplifting sensory message that enhances the perception of cleanliness.",
        contentLink: "scent/homecare",
      },
      {
        title: "Fine Fragrance",
        titleLink: "scent/finecare",
        img: WebpHeaderImage,
        content:
          "The fine fragrance sector is one of continuous evolution. When creating a luxury perfume inspired by a Mediterranean summer, we monitor olfactory trends, factor in evaporation kinetics, and keep our finger on the pulse of consumer preferences. Each element in the olfactory pyramid is thoughtfully curated, balancing artistry with market reception, to paint a vivid, lasting sensory picture.",
        contentLink: "scent/finecare",
      },

      {
        title: "Air Care",
        titleLink: "scent/aircare",
        img: WebpHeaderImage,
        content:
          "Air care fragrances are transformative agents. A lavender-infused room spray is not just about fragrance release; it's about malodor counteraction and space enhancement. We harness the calming properties of lavender, balance it with effective odor neutralizers, and ensure consistent diffusivity over time. The result is an olfactory narrative that changes the space's character into a serene haven.",
        contentLink: "scent/aircare",
      },

      {
        title: "Cosmetics",
        titleLink: "scent/cosmetics",
        img: WebpHeaderImage,
        content:
          "Fragrances in cosmetics amplify the product's appeal and the user's experience. When designing a scent for a luxury face cream, we factor in volatility rates, packaging interaction, and market appeal. The aroma does not merely exist; it immerses the user in an indulgent experience that starts the moment they uncap the jar and lasts throughout the product’s life cycle.",
        contentLink: "scent/cosmetics",
      },
    ],
  },
  {
    title: "Manufacturing",
    link: "manufacturing",
    children: [
      {
        title: "TasteGuard",
        titleLink: "manufacturing/tasteguard",
        img: WebpHeaderImage,
        content:
          "All the product innovations in the world would not matter if the product were not protected properly. Freshness is not just a quality; it's a promise - a promise that TasteGuard is designed to keep. We are proud to present FlavorGuard, our two-stage process that ensures the preservation and protection of your flavor powder products.",
        contentLink: "manufacturing/tasteguard",
      },
      {
        title: "Fresh Seal",
        titleLink: "manufacturing/freshseal",
        img: WebpHeaderImage,
        content:
          "FreshSeal, our proprietary Vacuum Sealing system, takes product preservation a step further. This is not simply about sealing a package; its about creating a consistent, dependable seal that protects the integrity of our products. The brilliance of this method is in its effectiveness and simplicity: by removing air from the packaging thoroughly, we minimize the presence of oxygen.",
        contentLink: "manufacturing/freshseal",
      },
    ],
  },
  {
    title: "Our Company",
    link: "who-we-are",
    children: [
      {
        title: "About AFF",
        titleLink: "who-we-are",
        img: WebpHeaderImage,
        content:
          "Welcome to Advanced Flavors & Fragrances, a melting pot of creativity, expertise, and innovation in the realm of sensory science. We are a team of dedicated professionals, with decades of experience in the flavor and fragrance industry, united by a shared vision - to transform ordinary experiences into extraordinary sensory journeys.",
        contentLink: "who-we-are",
      },
      {
        title: "Spotlight",
        titleLink: "spotlight",
        img: WebpHeaderImage,
        content:
          "At Advanced Flavors & Fragrances (AFF), we are proud to spotlight our esteemed distribution partners, Nerissa and Leonardo Gomez of Flavourium Aromatics Corporation. Their journey, both professionally in the flavor industry and personally as a couple, epitomizes the values and aspirations we hold dear at AFF.",
        contentLink: "spotlight",
      },
      {
        title: "Our Guarantee",
        titleLink: "guarantee",
        img: WebpHeaderImage,
        content:
          "We are committed to delivering quality products, learn more about our Guarantee.",
        contentLink: "guarantee",
      },
      {
        title: "Our Purpose",
        titleLink: "about#our-purpose",
        img: WebpHeaderImage,
        content:
          "Our purpose at AFF extends beyond the creation of flavors and fragrances. We believe in the transformative power of our craft - its ability to evoke emotions, spark memories, and even impact well-being.",
        contentLink: "about#our-purpose",
      },
      {
        title: "Our Values",
        titleLink: "about#our-values",
        list: [
          {
            title: "Innovation",
            link: "about#our-values",
          },
          {
            title: "Expertise",
            link: "about#our-values",
          },
          {
            title: "Quality",
            link: "about#our-values",
          },
          {
            title: "Customer Centricity",
            link: "about#our-values",
          },
        ],
      },
      {
        title: "Our Reach",
        titleLink: "about#our-reach",
        img: WebpHeaderImage,
        content:
          "Our work at AFF impacts lives far beyond our immediate surroundings. Our flavors and fragrances are woven into the fabric of everyday life worldwide, invoking emotions, awakening memories, and promoting healthier lifestyles.",
        contentLink: "about#our-reach",
      },
    ],
  },
  {
    title: "Contact Us",
    link: "contactus",
    children: [
      {
        title: "Contact Us",
        titleLink: "contactus",
        img: WebpHeaderImage,
        content:
          "We are here to help. Whether you have a question about our products, need assistance with an order, or just want to chat, we would love to hear from you.",
        contentLink: "contactus",
      },
    ],
  },
];

export { megaMenuList };
