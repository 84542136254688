import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ReactSelect from "react-select";
import { X } from "react-feather";
import countries from "../data/countries.json";

const categories = [
  {
    label: "Subsidiary",
    value: "Subsidiary",
  },
  {
    label: "Distributors/Agent",
    value: "Distributors/Agent",
  },
  {
    label: "End-Customer",
    value: "End-Customer",
  },
];

export default function Register() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [data, setData] = useState({});
  const origin =
    window.location.origin?.includes("localhost") ||
    window.location.origin?.includes("webs")
      ? "https://barry-erp-api-production.up.railway.app"
      : "https://api.advancedff.com";

  const categoryFromUrl = new URLSearchParams(search).get("role");

  const [selectedCategory, setSelectedCategory] = useState({
    label: categoryFromUrl,
    value: categoryFromUrl,
  });

  useEffect(() => {
    if (categoryFromUrl) {
      setSelectedCategory({
        label: categoryFromUrl,
        value: categoryFromUrl,
      });
    }
  }, [categoryFromUrl]);

  const [successPopup, setSuccessPopup] = useState(false);

  useEffect(() => {
    if (successPopup) {
      setTimeout(() => {
        setSuccessPopup(false);
        window.location.reload();
      }, 3000);
    }
  }, [successPopup]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const postUrl = `${origin}/api/v1/registrationRequest`;

      console.log({
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: data.address,
        country: data.country.value,
        category: selectedCategory.value,
        note: data.note || null,
      });

      const res = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          phone: data.phone,
          address: data.address,
          country: data.country.value,
          category: selectedCategory.value,
          note: data.note || null,
        }),
      });

      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      setSuccessPopup(true);
    } catch (e) {
      alert(e);
    }
  };

  return (
    <>
      <div className="Register__page">
        <div className="Register__page__content">
          <form
            onSubmit={handleSubmit}
            className="Register__page__content__form"
          >
            <div className="Register__page__content__form__heading">
              Register AFF
            </div>
            <div className="Register__page__content__form__info">
              We are excited to have your back. Log in now and access your
              account.
            </div>
            <div className="Register__page__content__form__inputs">
              <div className="contact__content__left__row__reg">
                <div className="contact__content__left__row__reg__icon">
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1084 9.325C11.6097 9.325 12.0904 9.51859 12.4449 9.86319C12.7993 10.2078 12.9984 10.6752 12.9984 11.1625V11.6004C12.9984 13.7913 10.3461 15.45 6.69844 15.45C3.05074 15.45 0.398438 13.8777 0.398438 11.6004V11.1625C0.398437 10.6752 0.597562 10.2078 0.952006 9.86319C1.30645 9.51859 1.78718 9.325 2.28844 9.325H11.1084ZM6.69844 0.75C7.19483 0.75 7.68637 0.845057 8.14498 1.02974C8.60359 1.21443 9.0203 1.48513 9.3713 1.82638C9.72231 2.16764 10.0007 2.57277 10.1907 3.01864C10.3807 3.46451 10.4784 3.94239 10.4784 4.425C10.4784 4.90761 10.3807 5.38549 10.1907 5.83136C10.0007 6.27723 9.72231 6.68236 9.3713 7.02362C9.0203 7.36487 8.60359 7.63557 8.14498 7.82026C7.68637 8.00494 7.19483 8.1 6.69844 8.1C5.69592 8.1 4.73446 7.71281 4.02557 7.02362C3.31669 6.33442 2.91844 5.39967 2.91844 4.425C2.91844 3.45033 3.31669 2.51558 4.02557 1.82638C4.73446 1.13719 5.69592 0.75 6.69844 0.75Z"
                      fill="white"
                    />
                  </svg>
                  <Input
                    name="username"
                    type="text"
                    title="Username"
                    placeholder="Enter Username"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    required={true}
                  />
                </div>
                <div className="contact__content__left__row__reg__icon">
                  <svg
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2684 0.796875H1.82844C0.904437 0.796875 0.156837 1.50563 0.156837 2.37188L0.148438 11.8219C0.148438 12.6881 0.904437 13.3969 1.82844 13.3969H15.2684C16.1924 13.3969 16.9484 12.6881 16.9484 11.8219V2.37188C16.9484 1.50563 16.1924 0.796875 15.2684 0.796875ZM15.2684 3.94688L8.54844 7.88438L1.82844 3.94688V2.37188L8.54844 6.30938L15.2684 2.37188V3.94688Z"
                      fill="white"
                    />
                  </svg>
                  <Input
                    name="email"
                    type="email"
                    title="Email"
                    placeholder="Enter Email"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="Register__page__content__form__inputs">
              <div className="contact__content__left__row__reg">
                <div className="contact__content__left__row__reg__icon">
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.18818 7.86284L8.86968 8.17061C8.86968 8.17061 8.11159 8.90166 6.0431 6.90554C3.97461 4.90941 4.73271 4.17836 4.73271 4.17836L4.93291 3.98404C5.4278 3.50709 5.4747 2.74071 5.04281 2.18087L4.16081 1.03741C3.62601 0.344404 2.59352 0.252683 1.98103 0.843776L0.882034 1.90367C0.578936 2.19718 0.375937 2.57629 0.400437 2.99753C0.463436 4.07576 0.966033 6.39462 3.76881 9.10005C6.74169 11.9686 9.53118 12.0827 10.6715 11.9794C11.0327 11.9468 11.3463 11.7688 11.599 11.5242L12.593 10.5649C13.265 9.91739 13.076 8.80655 12.2164 8.35338L10.8794 7.64746C10.3152 7.35056 9.62918 7.43752 9.18818 7.86284Z"
                      fill="white"
                    />
                  </svg>
                  <Input
                    name="phone"
                    type="tel"
                    title="Phone"
                    placeholder="Enter Phone"
                    value={data.phone}
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="Register__page__content__form__inputs">
              <div className="contact__content__left__row__reg">
                <Select
                  name="country"
                  title="Country"
                  placeholder="Select Country"
                  options={countries?.map((country) => ({
                    label: country.name,
                    value: country.name,
                  }))}
                  value={data.country}
                  onChange={(e) => setData({ ...data, country: e })}
                  required={true}
                />
              </div>
            </div>
            <div className="Register__page__content__form__inputs">
              <div className="contact__content__left__row__reg">
                <div className="contact__content__left__row__reg__icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11C21 14.074 19.324 16.59 17.558 18.395C16.6755 19.2869 15.7128 20.0956 14.682 20.811L14.256 21.101L14.056 21.234L13.679 21.474L13.343 21.679L12.927 21.921C12.6445 22.0818 12.325 22.1663 12 22.1663C11.675 22.1663 11.3555 22.0818 11.073 21.921L10.657 21.679L10.137 21.359L9.945 21.234L9.535 20.961C8.42298 20.2083 7.38707 19.3489 6.442 18.395C4.676 16.589 3 14.074 3 11C3 8.61305 3.94821 6.32387 5.63604 4.63604C7.32387 2.94821 9.61305 2 12 2ZM12 8C11.606 8 11.2159 8.0776 10.8519 8.22836C10.488 8.37913 10.1573 8.6001 9.87868 8.87868C9.6001 9.15726 9.37913 9.48797 9.22836 9.85195C9.0776 10.2159 9 10.606 9 11C9 11.394 9.0776 11.7841 9.22836 12.1481C9.37913 12.512 9.6001 12.8427 9.87868 13.1213C10.1573 13.3999 10.488 13.6209 10.8519 13.7716C11.2159 13.9224 11.606 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8Z"
                      fill="white"
                    />
                  </svg>

                  <Input
                    name="address"
                    type="text"
                    title="Address"
                    placeholder="Enter Address"
                    value={data.address}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="Register__page__content__form__label">
              Select Category
            </div>
            <div className="Register__page__content__form__buttons">
              {categories.map((category, i) => (
                <button
                  key={i}
                  type="button"
                  className={
                    selectedCategory.label === category.label
                      ? "Register__page__content__form__inputs__button Register__page__content__form__inputs__button__selected"
                      : "Register__page__content__form__inputs__button"
                  }
                  onClick={() => {
                    setSelectedCategory(category);
                    navigate(`/register?role=${category.value}`);
                  }}
                >
                  {category.label}
                </button>
              ))}
            </div>
            <div className="Register__page__content__form__label">Note</div>
            <div className="Register__page__content__form__inputs">
              <div className="contact__content__left__row__reg">
                <Textarea
                  className="contact__content__left__input__field__textarea__reg"
                  name="Message"
                  textarea
                  title="Message"
                  placeholder="Enter Message"
                  value={data.note}
                  onChange={(e) => setData({ ...data, note: e.target.value })}
                  required={false}
                />
              </div>
            </div>
            <div className="Register__page__content__form__buttons__sub">
              <button
                type="submit"
                className="Register__page__content__form__inputs__button__submit"
              >
                Submit
              </button>
            </div>
            {successPopup ? (
              <SuccessPopup onClose={() => setSuccessPopup(!successPopup)} />
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
}

function Select({ label, id, error, ...props }) {
  return (
    <div className="container__main__content__details__main__input__reg">
      <label
        htmlFor={id}
        className="container__main__content__details__main__input__label__reg"
      >
        {label}
      </label>
      <div className="container__main__content__listing__header__left__filter container__main__content__listing__header__left__filter__special__reg">
        <ReactSelect
          {...props}
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              padding: "0.25em 0",
              border: "1px solid black",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,

            colors: {
              ...theme.colors,
              primary75: "#db6220",
              primary25: "#db62205e",
              primary50: "#db62205e",
              primary: "#db6220",
            },
          })}
        />
      </div>
      {error && (
        <div className="container__main__content__details__main__input__error">
          {error}
        </div>
      )}
    </div>
  );
}
function Input({ textarea, required, label, error, list, ...props }) {
  return (
    <div className="contact__content__left__input__reg">
      {textarea ? (
        <textarea
          {...props}
          cols="30"
          rows="10"
          className="contact__content__left__input__field__reg"
          required={required}
          style={{ padding: "1em" }}
        />
      ) : (
        <input
          {...props}
          className="contact__content__left__input__field__reg"
          required={required}
          list={label + "s"}
          id={label}
        />
      )}
      {error !== "" ? (
        <div className="contact__content__left__input__error">{error}</div>
      ) : null}
      {list ? (
        <datalist id={label + "s"}>
          {list.map((item, i) => (
            <option key={i} value={item} />
          ))}
        </datalist>
      ) : null}
    </div>
  );
}
function Textarea({ textarea, required, label, error, list, ...props }) {
  return (
    <div className="contact__content__left__input__reg__two">
      <label
        htmlFor={props.id}
        className="contact__content__left__input__label"
      >
        {label}
        {required ? <span>*</span> : null}
      </label>
      {textarea ? (
        <textarea
          {...props}
          cols="30"
          rows="10"
          className="contact__content__left__input__field__textarea__reg"
          required={required}
          style={{ padding: "1em" }}
        />
      ) : (
        <input
          {...props}
          className="contact__content__left__input__field__textarea__reg"
          required={required}
          list={label + "s"}
          id={label}
        />
      )}
      {error !== "" ? (
        <div className="contact__content__left__input__error">{error}</div>
      ) : null}
      {list ? (
        <datalist id={label + "s"}>
          {list.map((item, i) => (
            <option key={i} value={item} />
          ))}
        </datalist>
      ) : null}
    </div>
  );
}
function SuccessPopup({ onClose, sendgif }) {
  return (
    <div className="Register__page__popup">
      <button
        onClick={onClose}
        className="Register__page__popup__content__closebutton"
      >
        <X />
      </button>
      <div className="Register__page__popup__content">
        <img src={sendgif} alt="" className="Register__page__popup__gif" />
        <div className="Register__page__popup__info">Successfully Submit</div>
        <div className="Register__page__popup__subinfo">
          We have received your request and will get back to you soon.
        </div>
      </div>
    </div>
  );
}
