import {
  WebpFlavorCardImg,
  WebpFlavorCardImg10,
  WebpFlavorCardImg11,
  WebpFlavorCardImg12,
  WebpFlavorCardImg13,
  WebpFlavorCardImg14,
  WebpFlavorCardImg15,
  WebpFlavorCardImg2,
  WebpFlavorCardImg3,
  WebpFlavorCardImg4,
  WebpFlavorCardImg5,
  WebpFlavorCardImg6,
  WebpFlavorCardImg7,
  WebpFlavorCardImg8,
  WebpFlavorCardImg9,
} from "../assets";

import Footer from "../components/Footer";
import TasteSolutionsCard from "../components/TasteSolutionsCard";
import { WebpTasteSolutionsBg } from "../assets";

export default function TasteSolutions() {
  return (
    <>
      <section className="about__hero">
        <img
          loading="lazy"
          className="about__hero__img"
          src={WebpTasteSolutionsBg}
          alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-Image Containg Buildings"
        />
        <div className="about__hero__content">
          <div className="about__hero__content__heading">Taste Solutions</div>
          <div className="about__hero__content__text">
            Embark with us on an epicurean expedition, where the past meets the
            present, where <span>science</span> meets <span>artistry</span>,
            where the ordinary becomes
            <span>extraordinary</span>.
          </div>
        </div>
      </section>
      <section className="taste__flavor">
        <div className="taste__flavor__content">
          <div className="taste__flavor__content__heading">
            Flavor <span>Applications</span>
          </div>
          <div className="taste__flavor__content__cards">
            <TasteSolutionsCard img={WebpFlavorCardImg} />
            <TasteSolutionsCard img={WebpFlavorCardImg2} />
            <TasteSolutionsCard img={WebpFlavorCardImg3} />
            <TasteSolutionsCard img={WebpFlavorCardImg4} />
            <TasteSolutionsCard img={WebpFlavorCardImg5} />
            <TasteSolutionsCard img={WebpFlavorCardImg6} />
            <TasteSolutionsCard img={WebpFlavorCardImg7} />
            <TasteSolutionsCard img={WebpFlavorCardImg8} />
            <TasteSolutionsCard img={WebpFlavorCardImg9} />
            <TasteSolutionsCard img={WebpFlavorCardImg10} />
            <TasteSolutionsCard img={WebpFlavorCardImg11} />
            <TasteSolutionsCard img={WebpFlavorCardImg12} />
            <TasteSolutionsCard img={WebpFlavorCardImg13} />
            <TasteSolutionsCard img={WebpFlavorCardImg14} />
            <TasteSolutionsCard img={WebpFlavorCardImg15} />
          </div>
          <div className="taste__flavor__content__text">
            At <span>Advanced Flavors & Fragrances</span>, we understand the
            complex chemistry and processing conditions of diverse food
            applications, providing exceptional flavor solutions that ensure
            outstanding performance across all applications.
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
