import { GifLoading, GifSend, SvgContactusmap } from "../assets";
import { useEffect, useState } from "react";

import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

export default function Contactus() {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");

  const handleNameChange = (e) => {
    if (e.target.value === "") {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    if (e.target.value === "") {
      setEmailError("Email is required");
    } else {
      setEmailError("");
    }
    setEmail(e.target.value);
  };
  const handleSubjectChange = (e) => {
    if (e.target.value === "") {
      setSubjectError("Subject is required");
    } else {
      setSubjectError("");
    }
    setSubject(e.target.value);
  };
  const handleMessageChange = (e) => {
    if (e.target.value === "") {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
    setMessage(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pxss1gf",
        "template_le9x0er",
        form.current,
        "7xAcUTkAwtio_EIZ8"
      )
      .then(() => {
        setIsModalOpen(true);
        setIsError(false);
        form.current.reset();
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        closeModal();
      }, 2500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isModalOpen]);
  return (
    <>
      {" "}
      <Helmet>
        <title> Contact Us | Advanced Flavors & Fragrances </title>{" "}
        <meta
          name="description"
          content="At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey."
        />
        <a
          href="https://www.advancedff.com/"
          title="advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors & Fragrances Pte. Ltd. Advanced Flavors and  Flavors & Fragrances Pte. Ltd. advnacedff   flavour flavour singapore"
        ></a>{" "}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/src/assets/advancedfflogo.png"
        />
        <link
          rel="shortcut icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          http-equiv="Icon"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite , advancedff, aff , ,aff  aff website ,   advancedff , advancedff, flavoursingapore , advancedff , 
 "
        />
        <meta
          name="news_keywords"
          content="AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite,  aff,   aff website,   advancedff, flavour, flavoursingapore, advancedff, Aff ,Advanced Flavors & Flavors, Food Flavors Singapore, Food Flavours"
        ></meta>
        <meta
          property="og:title"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          property="og:description"
          content="At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey."
        />
        <meta property="og:url" content="https://www.advancedff.com//" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />{" "}
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta
          property="og:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:site_name"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@advancedff" />
        <meta name="twitter:creator" content="@ " />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dhg7c7ypc/image/upload/v1698058590/Aff/aff_logo_it31yw.png"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/taste/contactus"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:url"
          content="https://www.advancedff.com/taste/contactus"
        />
        <meta name="twitter:domain" content="advancedff.com" />
        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:image:alt"
          content="About Us Advanced Flavors & Fragrances"
        />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="627" />
        <meta
          name="twitter:title"
          content=" About Us Advanced Flavors & Fragrances"
        />
        <meta
          name="twitter:description"
          content="At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey."
        />
        <link
          rel="canonical"
          href="https://www.advancedff.com/taste/contactus"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/contactus"
          hrefLang="en-us"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/contactus"
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/contactus"
          hrefLang="en"
        />
        <link
          rel="alternate"
          href="https://www.advancedff.com/taste/contactus"
          hrefLang="en-sg"
        />
      </Helmet>
      <section className="cotnactussection">
        <div className="cotnactussection__content">
          <div className="cotnactussection__content__heading">Contact Us</div>
          <div className="cotnactussection__content__map">
            <img
              loading="lazy"
              src={SvgContactusmap}
              alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-contactusmap"
            />
          </div>
        </div>
      </section>
      <section className="contactusformsection">
        <div className="contactusformsection__content">
          <div className="contactusformsection__content__left">
            <form
              className="contact__content__left contact__content__left__reverse"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="contact__content__left__row">
                <Input
                  name="name"
                  type="text"
                  label="Name"
                  title="Name"
                  required={true}
                  onChange={handleNameChange}
                  error={nameError}
                />
                <Input
                  name="Company"
                  type="text"
                  label="Company Name "
                  title="CompanyName"
                />
              </div>
              <div className="contact__content__left__row">
                <Input
                  type="email"
                  label="Email"
                  title="Email"
                  name="Email"
                  required={true}
                  onChange={handleEmailChange}
                  error={emailError}
                />
                <Input name="tel" type="tel" label="Phone" title="Phone" />
              </div>
              <Input
                type="Subject"
                label="Subject"
                title="Subject"
                name="Subject"
                required={true}
                onChange={handleSubjectChange}
                error={subjectError}
              />
              <Textareas
                className="contact__content__left__input__field__textarea"
                textarea
                label="How we can help you?"
                required={true}
                name="Message"
                onChange={handleMessageChange}
                error={messageError}
              />
              <button
                type="submit"
                value="Send"
                title="Submit"
                className="contact__content__left__button"
              >
                Submit
              </button>
            </form>
            <Modal isOpen={isModalOpen} contentLabel="Form Submission Status">
              <div className={`modal-content ${isError ? "error" : "success"}`}>
                <img
                  loading="lazy"
                  className="sendgif"
                  src={GifSend}
                  alt=" At AFF, we pioneer evocative aromas and tastes that inspire and endure. As an ambitious trailblazer in Asia, we envision a world where every Scent tells a story, and every Flavor is a journey AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey ,AdvancedFF, a leader in global Flavors & Fragrances, offers cutting-edge aromatic solutions, bespoke scents, and innovative flavor creations. With a commitment to excellence, our natural extracts, culinary aromas, and custom fragrance formulations redefine sensory experiences. Explore our diverse range of gourmet essences, aroma compounds, and unique fragrances crafted with expertise. From scent marketing to personalized aromatics, our top-rated flavor and fragrance design elevate culinary sensations. AdvancedFF sets the standard in fragrance trends, delivering premium scents, bespoke flavoring, and unparalleled fragrance diversity. Experience the artistry of AdvancedFF, where advanced technology meets olfactory mastery for a world-class sensory journey,advancedffwebsite aff  aff website   advancedff advancedff.com advancedff advancedff advancedgg advancedff
advancedgg affwebsite  Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors & Fragrances Pte. Ltd.
Advanced Flavors and  Flavors & Fragrances Pte. Ltd.
advnacedff   flavour flavour singapore advancedff-gif"
                />
                <h2>
                  {isError
                    ? "Error submitting the form."
                    : " We have received your request successfully. We will contact you soon."}
                </h2>
              </div>
            </Modal>
          </div>
          <div className="contactusformsection__content__left">
            <div className="contactusformsection__content__left__content">
              <div className="contactusformsection__content__left__content__heading">
                Advanced Flavors & Fragrances
              </div>
              <div className="contactusformsection__content__left__content__info__all">
                <div className="contactusformsection__content__left__content__info">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5209 4.15045C15.4961 5.37188 15.0582 6.50549 14.4951 7.58322C12.5556 11.2954 9.77069 14.2718 5.50752 15.8751C3.45742 16.6468 1.65176 16.1026 0.420829 14.4288C0.0978177 13.9911 0.103638 13.5334 0.461569 13.1329C1.10468 12.4131 1.7638 11.7026 2.45929 11.024C3.03984 10.4585 3.65239 10.4652 4.32024 10.9774C4.74656 11.3047 5.17869 11.6294 5.57591 11.986C5.88146 12.2614 6.12008 12.2481 6.45037 12.0166C8.50483 10.5796 10.1053 8.79933 11.3363 6.72636C11.5211 6.41501 11.4672 6.19681 11.1791 5.97727C10.7514 5.65129 10.3265 5.31998 9.92783 4.96473C9.3662 4.46445 9.30799 3.90563 9.79106 3.36676C10.4036 2.68154 11.0395 2.00962 11.7 1.36298C12.2849 0.789518 12.8073 0.752263 13.4955 1.23126C14.0149 1.59183 14.5038 1.99498 14.9709 2.41144C15.4976 2.88644 15.5296 3.51711 15.5209 4.15045Z"
                      fill="#DB6220"
                    />
                  </svg>
                  <a
                    className="contactusformsection__content__left__content__info"
                    href="tel:+65  6909 8635"
                  >
                    {" "}
                    +65 6909 8635
                  </a>
                </div>
                <div className="contactusformsection__content__left__content__info">
                  <svg
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0797 0.897949H2.27969C1.39969 0.897949 0.687687 1.59795 0.687687 2.4535L0.679688 11.7868C0.679688 12.6424 1.39969 13.3424 2.27969 13.3424H15.0797C15.9597 13.3424 16.6797 12.6424 16.6797 11.7868V2.4535C16.6797 1.59795 15.9597 0.897949 15.0797 0.897949ZM14.7597 4.2035L9.10369 7.64128C8.84769 7.79684 8.51169 7.79684 8.25569 7.64128L2.59969 4.2035C2.39969 4.07906 2.27969 3.86906 2.27969 3.6435C2.27969 3.12239 2.86369 2.81128 3.31969 3.0835L3.95361 3.46893C6.85706 5.23423 10.5023 5.23423 13.4058 3.46893L14.0397 3.0835C14.4957 2.81128 15.0797 3.12239 15.0797 3.6435C15.0797 3.86906 14.9597 4.07906 14.7597 4.2035Z"
                      fill="#DB6220"
                    />
                  </svg>
                  <a
                    className="contactusformsection__content__left__content__info"
                    href="mailto::enquiry@advancedff.com"
                  >
                    {" "}
                    enquiry@advancedff.com
                  </a>
                </div>
              </div>
              <div className="contactusformsection__content__left__content__info__all">
                <div className="contactusformsection__content__left__content__info">
                  <svg
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6645 0.340063C10.381 0.376597 13.2546 3.1336 13.0549 6.50099C12.9915 7.56583 12.5072 8.53711 12.0902 9.51552C10.6763 12.8312 8.89596 15.9955 7.2706 19.2247C7.13028 19.5036 6.90031 19.5259 6.64598 19.5419C6.29616 19.5633 6.07398 19.3931 5.92782 19.1098C5.35972 18.0111 4.777 16.9195 4.21475 15.819C2.98014 13.4042 1.78352 10.9769 0.716511 8.49255C-0.587291 5.45843 0.708715 3.07568 2.45686 1.72035C3.66614 0.782039 5.07323 0.318677 6.6645 0.340063ZM6.60993 3.91864C5.16775 3.9231 4.05494 4.94249 4.06176 6.25505C4.06859 7.54712 5.22135 8.59236 6.63429 8.58612C8.05697 8.57988 9.17465 7.54445 9.17368 6.23367C9.17173 4.91665 8.06184 3.91419 6.60993 3.91864Z"
                      fill="#DB6220"
                    />
                  </svg>
                  15 Senoko Drive, JTC Food Hub @ Senoko, #05-05, S758202
                </div>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.522570692816!2d103.7962550741213!3d1.4602707112127404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da13408a1d9f0f%3A0xce9c2c569b644616!2sJTC%20Food%20Hub%20%40%20Senoko!5e0!3m2!1sen!2s!4v1692212884989!5m2!1sen!2s"
                width="100%"
                height="320"
                style={{ border: "0", borderRadius: "30px", marginTop: "20px" }}
                allowFullScreen=""
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
function Input({ textarea, required, label, error, list, ...props }) {
  return (
    <div className="contact__content__left__input">
      <label
        htmlFor={props.id}
        className="contact__content__left__input__label"
      >
        {label}
        {required ? <span>*</span> : null}
      </label>
      {textarea ? (
        <textarea
          {...props}
          cols="30"
          rows="10"
          className="contact__content__left__input__field"
          required={required}
          style={{ padding: "1em" }}
        />
      ) : (
        <input
          {...props}
          className="contact__content__left__input__field"
          required={required}
          list={label + "s"}
          id={label}
        />
      )}
      {error !== "" ? (
        <div className="contact__content__left__input__error">{error}</div>
      ) : null}
      {list ? (
        <datalist id={label + "s"}>
          {list.map((item, i) => (
            <option key={i} value={item} />
          ))}
        </datalist>
      ) : null}
    </div>
  );
}
function Textareas({ textarea, required, label, error, list, ...props }) {
  return (
    <div className="contact__content__left__input">
      <label
        htmlFor={props.id}
        className="contact__content__left__input__label"
      >
        {label}
        {required ? <span>*</span> : null}
      </label>
      {textarea ? (
        <textarea
          {...props}
          cols="30"
          rows="10"
          className="contact__content__left__input__field__textarea"
          required={required}
          style={{ padding: "1em" }}
        />
      ) : (
        <input
          {...props}
          className="contact__content__left__input__field__textarea"
          required={required}
          list={label + "s"}
          id={label}
        />
      )}
      {error !== "" ? (
        <div className="contact__content__left__input__error">{error}</div>
      ) : null}
      {list ? (
        <datalist id={label + "s"}>
          {list.map((item, i) => (
            <option key={i} value={item} />
          ))}
        </datalist>
      ) : null}
    </div>
  );
}
