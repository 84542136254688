import { Link } from "react-router-dom";
import authenticDocument from "../assets/authenticDocument.png";
import missingAuthenticDocument from "../assets/missingAuthenticDocument.png";
import notAuthenticDocument from "../assets/notAuthenticDocument.png";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function Verify() {
  const id = window.location.search.split("=")[1];
  const origin =
    window.location.origin?.includes("localhost") ||
    window.location.origin?.includes("webs")
      ? "https://barry-erp-api-production.up.railway.app"
      : "https://api.advancedff.com";

  const authUrl = id ? `${origin}/api/v1/document/authenticate/${id}` : null;

  const settingUrl = id ? `${origin}/api/v1/setting` : null;

  const { data: settings } = useSWR(settingUrl, fetcher);

  const { data } = useSWR(authUrl, fetcher, { suspense: true });

  if (!id) {
    return (
      <div className="document__container">
        <img
          src={missingAuthenticDocument}
          alt="missingAuthenticDocument"
          className="document__container__image"
        />
        <div className="document__container__info">
          Please provide a document to verify
        </div>
        <Link to="/" className="document__container__button">
          Go Back
        </Link>
      </div>
    );
  }

  if (data.authenticated) {
    return (
      <div className="document__container">
        <img
          src={authenticDocument}
          alt="authenticDocument"
          className="document__container__image"
        />
        <div className="document__container__heading">
          Document is authentic
        </div>
        <div className="document__container__info">
          Thank you for using Advanced Freight Factoring. Your document{" "}
          <span>({data.document.name})</span> has been authenticated by our
          system. Please contact us for more information.
        </div>
        <a
          href={`mailto:${settings?.email}`}
          className="document__container__button"
        >
          Contact Us
        </a>
      </div>
    );
  }

  return (
    <div className="document__container">
      <img
        src={notAuthenticDocument}
        alt="notAuthenticDocument"
        className="document__container__image"
      />
      <div className="document__container__heading">
        Document is not authentic
      </div>
      <div className="document__container__info">
        Thank you for using Advanced Freight Factoring. Your document is not
        authentic and may have been altered. Please contact us for more
        information.
      </div>
      <a
        href={`mailto:${settings?.email}`}
        className="document__container__button"
      >
        Contact Us
      </a>
    </div>
  );
}
